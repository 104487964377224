// help functions
var CloudflowObject = require("./CloudflowObject.js");
var EmptyStringObject = { // object simulation a string who provides false for each check
    startsWith: function(){return false;},
    endsWith: function(){return false;},
    indexOf: function(){return-1;},
    toUpperCase: function(){return this;}
}
var zeroRegExp = new RegExp("%0", "g");
var oneRegExp = new RegExp("%1", "g");
// 
var translationDict = Object.freeze({ 
    "and": " && ",
    "or": " || ",
    "not": " true !== ",
    "(": "(",
    ")": ")",
    "equal to": " == %1 ",
    "strict equal to": " === %1 ",
    "not equal to": " !== %1 ",
    "exists": " !== undefined ",
    "does not exist": " === undefined ",
    "ends with": " makeString(%0).endsWith(''+%1) ",
    "ends like": " makeString(%0).toUpperCase().endsWith((''+%1).toUpperCase()) ",
    "begins with": " makeString(%0).startsWith(''+%1) ",
    "begins like": " makeString(%0).toUpperCase().startsWith((''+%1).toUpperCase()) ",
    "contains" : " makeString(%0).indexOf(''+%1) >= 0 ",
    "contains text like" : " makeString(%0).toUpperCase().indexOf((''+%1).toUpperCase()) >= 0 ",
    "is like" : "(''+%0).toUpperCase() == (''+%1).toUpperCase() ",
    "less than": " (%0 === null ? NaN : %0) < (%1 === null ? NaN : %1) ",
    "less than or equal to": " (%0 === null ? NaN : %0) <= (%1 === null ? NaN : %1) ",
    "greater than": " (%0 === null ? NaN : %0) > (%1 === null ? NaN : %1) ",
    "greater than or equal to": " (%0 === null ? NaN : %0) >= (%1 === null ? NaN : %1) ",
    "in": "_.contains(%1, %0) ",
    "in like": " %1.map(function(e){return makeString(e).toUpperCase()}).includes((''+%0).toUpperCase())",
// "in": "_.find($.makeArray(%0),function(m){return _.contains(%1,m);}) !== undefined ",
    "contains element matching": " " /** @todo */
});

var makeArrayArg = {
    "in": true
}
/**
 * @constructor QueryApplier
 * @description A hulp class that evaluates a query (in api style) on objects and rows in javascript
 * @private
 * @param {Array.<String>} pQuery the query in api style
 */
var QueryApplier = function(pQuery) {
    if (Array.isArray(pQuery) === false) {
        throw new Error("The query must be an array");
    }
    this.validateFunction = this._createFunction(this._createJSFunctionString(pQuery));
}

QueryApplier.prototype = {
    
    constructor: QueryApplier,

    _createJSFunctionString: function(pQuery){
        if (pQuery.length === 0) {
            return " true;" // pass all if query is empty, everything is valid!
        }
        var functionStrings = [];
        for (var i=0; i<pQuery.length; i++) {
            var translation = translationDict[pQuery[i]];
            if (translation === undefined) {
                // specific keys to be compared with
                // use JSON.stringify, so it correctly makes arguments for booleans, null, undefined, arrays, object and even strings
                functionStrings.push(" translate(" + JSON.stringify(pQuery[i]) + ")");
            } else if (translation.indexOf("%0") >= 0) {
                var inArray;
                if (makeArrayArg[pQuery[i]] === true) {
                    if (Array.isArray(pQuery[i+1])) {
                        inArray = JSON.stringify(pQuery[i+1]);
                    } else if (pQuery[i+1] === "(") {
                        inArray = "[";
                        for (var a = i+2 ; a<pQuery.length; a++) {
                            if (pQuery[a] === ")") {
                                i++;
                                break;
                            }
                            inArray += JSON.stringify(pQuery[a]) + ",";
                            i++;
                        }
                        inArray = inArray.slice(0, -1);
                        inArray += "]";
                    } else {
                        inArray = JSON.stringify([pQuery[i+1]]);
                    }
                } else {
                    inArray = JSON.stringify(pQuery[i+1]);
                }
                functionStrings[functionStrings.length - 1] = translation.replace(oneRegExp, inArray).replace(zeroRegExp, functionStrings[functionStrings.length - 1]);
                i++;
            } else if (translation.indexOf("%1") >= 0) {
                // some compare equations
                var replacer = pQuery[i+1];
                if (typeof replacer === "string") {
                    replacer = '"' + replacer + '"';
                }
                functionStrings.push(translation.replace(oneRegExp, replacer));
                i++;
            } else {
                // connected symbols
                functionStrings.push(translation);
            }
        }
        return functionStrings.join("");
    },

    _createFunction: function(pString) {
        if (typeof pString !== "string") {
            throw new Error("pString must be a string");
        }
        var argum = ["translate", "makeString", "$", "window", "document", "jQuery", "Function"];
        return new Function(argum, "'use strict';return " + pString);
    },

    /**
     * @description Evaluate the object. Is the object valid compared to the query?
     * @function
     * @name QueryApplier#validate
     * @param {Object} pObject The tobject to be valuated
     * @returns {Boolean} true if the object is valid
     */
    validate: function(pObject) {
        return this.validateFunction(function(pObjectPath){return CloudflowObject.getParameter(pObject, pObjectPath);}, 
                                     function(s){if(["string", "number", "boolean"].includes(typeof s)){return ""+s;}return EmptyStringObject;});
    },

    /**
     * @description Evaluate the condition as condition, there are no objects present
     * @function
     * @name QueryApplier#validateNaked
     * @returns {Boolean} true if the condition is true, false otherwise
     */
    validateNaked: function(){
        return this.validateFunction(function(pObjectPath){return pObjectPath;}, 
                                     function(s){if(["string", "number", "boolean"].includes(typeof s)){return ""+s;}return EmptyStringObject;});
    },

    /**
     * @description Evaluate all the rows and filter out those who are valid agains the query
     * @function
     * @name QueryApplier#apply
     * @param {Array.<Object>} pRows The rows to be evaluated
     * @returns {Array.<Object>} the rows who are valid agains the query
     */
    apply: function(pRows) {
        var validRows = [];
        if (Array.isArray(pRows)) {
            for (var i=0; i<pRows.length; i++) {
                if (this.validate(pRows[i])) {
                    validRows.push(pRows[i]);
                }
            }
        }
        return validRows;
    }
}

module.exports = QueryApplier;