var Location = {
	/**
	 * @description Get the current window.location, usefull for testing mocking
	 * @function
	 * @name Location#getLocation
	 */
	getLocation: function() {
		return window.location;
    },

	/**
	 * @description force an URL to be the same origin as the current one, throw an error if it is of strange origin
	 * @function
	 * @name Location#forceSameOrigin
	 * @param {String} pURL the url to be force
	 * @returns {String} url with same origin 
	 */
	forceSameOrigin: function(pURL) {
		if (typeof pURL !== "string" || pURL.length <= 0) {
			throw new Error("url parameter should be a not empty string");
		}
		if (pURL[0] === "/") {
			// force same origin, is pointing to root
			return window.location.origin + pURL;
		} else if (pURL.indexOf(window.location.origin) === 0) {
			// contains already same origin, leave it
			return pURL;
		} else {
			// could do nothing, probably wrong origin!!
			throw new Error("redirection to other origin blocked");
		}
	}    
};

module.exports = Location;