var connections = [];

/**
 * @constructor WhitepaperConnection
 * @description An object containing a connection to a whitepaper. With this you have an api like call to workflows. Create the object with the settings.
 * Call the start function and wait for the results, via a $.Deferred. This handles the polling itself.
 * @param {String} pWhitepaperName The name of the workflow
 * @param {String} pWhitepaperInputName The input name of the "Start from Kiosk" or the "Start from Web request"
 * @param {Object} pInputParameters The input parameters to be given to the "Start from ..." node
 * @param {Object} pConnection settings for the Start with DBIO, leave undefined for "Start from Kiosk and Start from Web Request"
 * @param {Boolean} pLongLive prevent stopping if leaving page, this connection may not be automaticaly aborted. Default is false 
 * @example var connection = new WhitepaperConnection("WhitepaperTest", "Input Name", {"type": "test", "value": 42})
 * @return {nm$_WhitepaperConnection.WhitepaperConnection}
 */
function WhitepaperConnection(pWhitepaperName, pWhitepaperInputName, pInputParameters, pConnection, pLongLive) {
    this._setWhitepaper(pWhitepaperName);
    this._setWhitepaperInput(pWhitepaperInputName);
    this._setConnection(pConnection);
    this._setInputParameters(pInputParameters);
    if (window.nixps !== undefined && nixps.cloudflow !== undefined && nixps.cloudflow.LogListener !== undefined) {
        this._setForceFirstPolling(nixps.cloudflow.LogListener.isActive());
    } else {
        this._setForceFirstPolling(false);
    }
    this.setStartPollingTime(3);
    this.setPollingTime(3);
    this.workableID = null;
    this.jacketID = null;
    this.isDead = false;
    this.reasonToStop = null;
    this.started = false;
    connections.push(this);
    this.longlive = false; 
    this._setLongLive(pLongLive);
};

WhitepaperConnection.prototype = {

    constructor: WhitepaperConnection,

    _setWhitepaper: function(pWhitepaperName){
        if (typeof pWhitepaperName !== "string" || pWhitepaperName.length <= 0) {
            throw new Error("parameter whitepapername must be a not emtpy string");
        }
        this.whitepaper = pWhitepaperName;
    },
    
    _setWhitepaperInput: function(pWhitepaperInputName) {
        if (typeof pWhitepaperInputName !== "string" || pWhitepaperInputName.length <= 0) {
            throw new Error("parameter whitepapername must be a not emtpy string");
        }
        this.inputname = pWhitepaperInputName;
    },
    
    _setConnection: function(pConnection) {
        if (pConnection !== undefined && pConnection.connectionType === "database_proxy") {
            this.connectionType = "database_proxy";
            this.connectionFunctionName = pConnection.connectionFunctionName;
            this.connectionCallOptions = pConnection.connectionCallOptions;
        } else {
            this.connectionType = "hub";
            this.connectionFunctionName = "start_from";
            this.connectionCallOptions = {};
        }
    },
    
    _setInputParameters: function(pInputParameters) {
        if (this.connectionType === "hub") {
            if (pInputParameters === undefined || pInputParameters === null) {
                this.inputparameters = {};
                return;
            }
            if ($.isPlainObject(pInputParameters) === false) {
                throw new Error("parameter whitepapername must be a plain object");
            }
            this.inputparameters = structuredClone(pInputParameters);
        } else {
            if (pInputParameters === undefined || pInputParameters === null) {
                this.inputparameters = [];
                return;
            }
            if (Array.isArray(pInputParameters) === false) {
                throw new Error("parameter whitepapername must be a plain object");
            }
            this.inputparameters = pInputParameters;
        }
    },
    
    _setLongLive: function(pLongLive) {
        if (pLongLive === true) {
            this.longlive = true;
        } else {
            // default value
            this.longlive = false; 
        }
    },

    /**
     * @description set if we need to force the first polling or not, is justed durring debugging
     * @function
     * @name WhitepaperConnection#_setForceFirstPolling
     * @private
     * @param {Boolean} pForceFirstPolling 
     * @returns {undefined}
     */
    _setForceFirstPolling: function(pForceFirstPolling){
        if (pForceFirstPolling === true) {
            this.forceFirstPolling = true;
        } else {
            this.forceFirstPolling = false;
        }
    },

    /**
     * @description Set the first timeout during start up on a different number.
     * @name WhitepaperConnection#setStartPollingTime
     * @function 
     * @param {Number} pStartPollingTime The delay in seconds
     * @return {undefined}
     */
    setStartPollingTime: function(pStartPollingTime) {
        if (typeof pStartPollingTime !== "number" || pStartPollingTime <= 0) {
            throw new Error("setStartPollingTime parameter must be a positive number");
        }
        this.startPollingTime = pStartPollingTime;
    },
    
    /**
     * @description Set delay between pollings on a different number (not the first one)
     * @name WhitepaperConnection#setPollingTime
     * @function
     * @param {Number} pPollingTime The delay in seconds
     * @return {undefined}
     */
    setPollingTime: function(pPollingTime) {
        if (typeof pPollingTime !== "number" || pPollingTime <= 0) {
            throw new Error("setPollingTime parameter must be a positive number");
        }
        this.pollingTime = pPollingTime;
    },
    
    /**
     * @description Check if the whitepaper and input exits or not?
     * @name WhitepaperConnection#exist
     * @function
     * @returns {Deferred} true if the whitepaper exists, false otherwise
     */
    exist: function() {
        return WhitepaperConnection.exist(this.whitepaper, this.inputname);
    },
    
    /**
     * @name WhitepaperConnection#getWorkableID
     * @description Get the current workable id if the polling has entered the first state or null
     * @function
     * @return {String} workableId or null
     */
    getWorkableID: function(){
        return this.workableID;
    },
    
    /**
     * @name WhitepaperConnection#getJacketID
     * @description Get the current jacket id if the polling has entered the first state or null
     * @function
     * @return {String} workableId or null
     */
    getJacketID: function(){
        return this.jacketID;
    },
    
    /**
     * @description check if a workable has already been started or not.
     * @function
     * @name WhitepaperConnection#isStarted
     * @returns {Boolean} true if the workable has started already
     */
    isStarted: function() {
        return this.started === true;
    },
    
    /**
     * @function
     * @description Check if the workable has been stopped by the stop methode 
     * @name  WhitepaperConnection#isAlive
     * @returns {Boolean} true if the workable has been aborted by the stop or not 
     */
    isAlive: function() {
        return this.isDead === false;
    },
    
    /**
     * @description Is the connection a long live allowed?
     * @function
     * @name WhitepaperConnection#hasLongLive
     * @returns {Boolean} true if the connection may live longer, false otherwise
     */
    hasLongLive: function(){
        return this.longlive;
    },

    /**
     * @name WhitepaperConnection#stop
     * @description Stop the polling and clean up, the user is not interested anymore in the results
     * @function
     * @return {undefined}
     */
    stop: function(pReason) {
        if (this.isAlive() === false) {
            return; // prevent killing dead connections ...
        }
        this._stop(pReason);
        if (this.getWorkableID() !== null && window.api_async !== undefined && api_async.hub !== undefined) {
            var that = this;
            // only in case we have a workable id
            api_async.hub.abort_workable(this.getWorkableID(), false, function() {

            }, function(pError) {
                console.error(pError);
            });
        }
    },
    
    /**
     * @name WhitepaperConnection#_stop
     * @description Function runs when the connection is closed
     * @function
     * @private
     * @return {undefined}
     */
    _stop: function(pReason) {
        this.isDead = true;
        if (typeof pReason === "string") {
            this.reasonToStop = pReason;
        } else {
            this.reasonToStop = "???";
        }
        // remove yourself of the list and do this async
        var that = this;
        setTimeout(function(){
            for (var i=0; i<connections.length; i++) {
                if (connections[i] === that) {
                    connections.splice(i, 1);
                    break;
                }
            }
        });
    },
    
    /**
     * @name WhitepaperConnection#start
     * @description Start the workflow
     * @function
     * @return {Deferred}
     */
    start: function() {
        if (this.isStarted() === true) {
            return $.Deferred().reject({"error": "no_restart"});
        }
        this.started = true;
        if (this.connectionType === "hub") {
            return this._startHub();
        } else {
            return this._startProxy();
        }
    },
    
    _startHub: function(){
        var that = this;
        if (this.forceFirstPolling === true) {
            // polling time is minimum a second, can not be smaller,
            // so do a normal start which garanties us a "jacket_id" and "workable_id"
            return $.Deferred(function(pDefer){
                that.justStart(true).then(function(){ 
                    that._polling(pDefer); 
                }, pDefer.reject);
            });
        }
        return $.Deferred(function(pDefer){
            api_async.hub.process_from_whitepaper_with_variables(that.whitepaper, that.inputname, that.inputparameters, that.startPollingTime,
                function(pResults) {
                    // do we get results ??
                    if (pResults !== undefined && pResults.timed_out === true && pResults.workable_id !== undefined) {
                        that.workableID = pResults.workable_id;
                        that.jacketID = pResults.jacket_id;

                        if (that.isAlive() === false) {
                            pDefer.reject({
                                "error": "stop_called", 
                                "error_reason": that.reasonToStop,
                                "debug": {
                                    "debug_type": "workflow",
                                    "whitepaperName": that.whitepaper,
                                    "whitepaperInputName": that.inputname
                                }
                            });
                            return;
                        }

                        pDefer.notify({
                            workableID: that.workableID, 
                            jacketID: that.jacketID,
                            nodeName: pResults.node_name,
                            milestone: pResults.milestone,
                            pollingCount: 0
                        });
                        that._polling(pDefer);
                    } else if (pResults !== undefined && pResults.no_results === true) {
                        that.workableID = pResults.workable_id;
                        that.jacketID = pResults.jacket_id;
                        var debugURL = "/portal.cgi?quantum&jacketId=" + pResults.jacket_id + "&workableId=" + pResults.workable_id;
                        console.error("probably an error in the workflow " + that.whitepaper + " ! debug: " + window.location.origin + debugURL);
                        pDefer.reject({
                            "error": "no_results",
                            "debug": {
                                "debug_type": "workflow",
                                "whitepaperName": that.whitepaper,
                                "whitepaperInputName": that.inputname,
                                "jacketID": pResults.jacket_id,
                                "workableID": pResults.workable_id,
                                "nodeName": pResults.node_name
                            }
                        });
                    } else if (pResults !== undefined && pResults.status === 303 && 
                        typeof pResults.content_type === "string" && pResults.contents !== undefined) {
                            if (typeof pResults.contents === "string" && pResults.contents.length > 0) {
                                pDefer.notify({action: "redirect", url: pResults.contents, defer: pDefer});
                            } else {
                                pDefer.reject({
                                    "error": "no_link_redirect",
                                    "debug": {
                                        "debug_type": "workflow",
                                        "whitepaperName": that.whitepaper,
                                        "whitepaperInputName": that.inputname
                                    }
                                });
                            }
                    } else if (pResults !== undefined && pResults.status >= 500 && pResults.status <= 599) {
                        pDefer.reject(pResults.contents);
                    } else {
                        that._stop("end_success_call");
                        pDefer.resolve(pResults);
                    } 
                },
                function(pError){
                    console.error(pError);
                    pDefer.reject(pError);
            });
        });
    },
    
    _startProxy: function(){
        var that = this;
        return $.Deferred(function(pDefer) {
            var goodCallBack = function(pResults) {
                // do we get results ??
                if (pResults !== undefined && pResults.timed_out === true && pResults.workable_id !== undefined) {
                    if (that.isAlive() === false) {
                        pDefer.reject({
                            "error": "stop_called", 
                            "error_reason": that.reasonToStop, 
                            "debug": {
                                "debug_type": "workflow",
                                "whitepaperName": that.whitepaper,
                                "whitepaperInputName": that.inputname
                            }});
                        return;
                    }
                    that.workableID = pResults.workable_id;
                    that.jacketID = pResults.jacket_id;
                    pDefer.notify({
                        workableID: that.workableID, 
                        jacketID: that.jacketID, 
                        nodeName: pResults.node_name,
                        milestone: pResults.milestone,
                        pollingCount: 0
                    });
                    that._polling(pDefer);
                } else if (pResults !== undefined && pResults.no_results === true) {
                    var debugURL;
                    if (typeof pResults.workable_id === "string" && pResults.workable_id.length > 0) {
                        that.workableID = pResults.workable_id;
                        that.jacketID = pResults.jacket_id;
                        debugURL = "/portal.cgi?quantum&jacketId=" + pResults.jacket_id + "&workableId=" + pResults.workable_id;
                    } else {
                        debugURL = "/portal.cgi?quantum&whitepaperName=" + that.whitepaper;
                    }
                    console.error("probably an error in the workflow " + that.whitepaper + " ! debug: " + window.location.origin + debugURL);
                    pDefer.reject({
                        "error": "no_results",
                        "debug": {
                            "debug_type": "workflow",
                            "whitepaperName": that.whitepaper,
                            "whitepaperInputName": that.inputname,
                            "jacketID": pResults.jacket_id,
                            "workableID": pResults.workable_id,
                            "nodeName": pResults.node_name
                        }
                    });
                } else {
                    that._stop("end_success_call");
                    pDefer.resolve(pResults);
                } 
            };
            var errorCallback = function(pError){
                    console.error(pError);
                    pDefer.reject(pError);
                };
            that.connectionCallOptions.time_out = that.startPollingTime;
            var apiCallArguments = [{"whitepaper_name": that.whitepaper, "input_name": that.inputname}].concat(that.inputparameters); 
            apiCallArguments = apiCallArguments.concat([that.connectionCallOptions, goodCallBack, errorCallback]);
            api_async.database_proxy[that.connectionFunctionName].apply(api_async.database_proxy, apiCallArguments);
        });
    },
    
    
    _polling: function(pDefer) {
        var that = this;
        this._pollResults(pDefer).done(function(pResults) {
            that._stop("end_success_call");
            pDefer.resolve(pResults);
        }).fail(function(pError){
            if (that.isAlive() === false) {
                // do nothing, 
                // just prevent further action, if element is zomby
                pDefer.reject({
                    "error": "stop_called", 
                    "error_reason": that.reasonToStop,
                    "debug": {
                        "debug_type": "workflow",
                        "whitepaperName": that.whitepaper,
                        "whitepaperInputName": that.inputname
                    }});
                return;
            } else if (pError === "wait") {
                that._polling(pDefer);
            } else {
                console.error(pError);
                if (pError !== undefined && pError !== null && pError.debug === undefined) {
                    pError.debug = {
                        "debug_type": "workflow",
                        "whitepaperName": that.whitepaper,
                        "whitepaperInputName": that.inputname,
                        "jacketID": that.jacketID,
                        "workableID": that.workableID,
                    }
                    if (pError.error_code === "Workable does not exist") {
                        delete pError.debug.jacketID;
                        delete pError.debug.workableID;
                    }
                }
                pDefer.reject(pError);
            }
        });
    },
    
    _pollResults: function(pDefer) {
        if (this.connectionType === "hub") {
            return this._pollResultsHub(pDefer);
        } else {
            return this._pollResultsProxy(pDefer);
        }
    },
    
    _pollResultsHub: function(pPreviousDefer) {
        var that = this;
        return $.Deferred(function(pDefer) {
            api_async.hub.poll_for_workable_result(that.workableID, that.pollingTime,  function(pResults) {
                if (pResults !== undefined && pResults.timed_out === true) {
                    if (typeof pResults.milestone === "string" || typeof pResults.node_name === "string") {
                        pPreviousDefer.notify({
                            workableID: that.workableID, 
                            jacketID: that.jacketID, 
                            nodeName: pResults.node_name,
                            milestone: pResults.milestone
                        })
                    }
                    // waiting
                    pDefer.reject('wait');
                } else if (pResults !== undefined && pResults.no_results === true) {
                    var debugURL = "/portal.cgi?quantum&jacketId=" + pResults.jacket_id + "&workableId=" + pResults.workable_id;
                    console.error("probably an error in the workflow " + that.whitepaper + " ! debug: " + window.location.origin + debugURL);
                    pDefer.reject({
                        "error": "no_results", 
                        "debug": {
                            "debug_type": "workflow",
                            "whitepaperName": that.whitepaper,
                            "whitepaperInputName": that.inputname,
                            "jacketID": pResults.jacket_id,
                            "workableID": pResults.workable_id,
                            "nodeName": pResults.node_name
                        },
                        "milestone": pResults.milestone
                    });
                } else if (pResults !== undefined && pResults.result !== undefined && 
                        pResults.result.status === 303 &&
                        typeof pResults.result.content_type === "string" && pResults.result.contents !== undefined) {
                            if (typeof pResults.result.contents === "string" && pResults.result.contents.length > 0) {
                                pPreviousDefer.notify({action: "redirect", url: pResults.result.contents, defer: pPreviousDefer});
                            } else {
                                var debugURL = "/portal.cgi?quantum&jacketId=" + pResults.jacket_id + "&workableId=" + pResults.workable_id;
                                console.error("debug: " + window.location.origin + debugURL);
                                pDefer.reject({
                                    "error": "no_link_redirect", 
                                    "debug": {
                                        "debug_type": "workflow",
                                        "whitepaperName": that.whitepaper,
                                        "whitepaperInputName": that.inputname,
                                        "jacketID": pResults.jacket_id,
                                        "workableID": pResults.workable_id,
                                        "nodeName": pResults.node_name
                                    }
                                });
                            }
                } else if (pResults !== undefined && pResults.result !== undefined && pResults.result.status >= 500 && pResults.result.status <= 599){
                    pDefer.reject(pResults.result.contents);
                } else {
                    pDefer.resolve(pResults.result);
                } 
            }, function(pError){
               console.error(pError);
               pDefer.reject(pError);
            });
        });
    },
    
    _pollResultsProxy: function(pPreviousDefer){
        var that = this;
        return $.Deferred(function(pDefer) {
            api_async.database_proxy.poll_for_result(that.workableID, that.pollingTime,  function(pResults) {
                if (pResults !== undefined && pResults.timed_out === true) {
                    if (typeof pResults.milestone === "string" || typeof pResults.node_name === "string") {
                        pPreviousDefer.notify({
                            workableID: that.workableID, 
                            jacketID: that.jacketID, 
                            nodeName: pResults.node_name,
                            milestone: pResults.milestone
                        })
                    }
                    // waiting
                    pDefer.reject("wait");
                } else if (pResults !== undefined && pResults.no_results === true) {
                    var debugURL;
                    if (typeof pResults.workable_id === "string" && pResults.workable_id.length > 0) {
                        debugURL = "/portal.cgi?quantum&jacketId=" + pResults.jacket_id + "&workableId=" + pResults.workable_id;
                    } else {
                        debugURL = "/portal.cgi?quantum&whitepaperName=" + that.whitepaper;
                    }
                    console.error("probably an error in the workflow " + that.whitepaper + " ! debug: " + window.location.origin + debugURL);
                    pDefer.reject({
                        "error": "no_results",
                        "debug": {
                            "debug_type": "workflow",
                            "whitepaperName": that.whitepaper,
                            "whitepaperInputName": that.inputname,
                            "jacketID": pResults.jacket_id,
                            "workableID": pResults.workable_id
                        }
                    });
                } else {
                    pDefer.resolve(pResults);
                } 
            }, function(pError){
               console.error(pError);
               pDefer.reject(pError);
            });
        });
    },
    
    /**
     * @description Just start and do not wait for results
     * @function
     * @name WhitepaperConnection#justStart
     * @return {Deferred}
     */
    justStart: function(pIgnoreCheckes) {
        if (pIgnoreCheckes !== true) {
            if (this.isStarted() === true) {
                return $.Deferred().reject({"error": "no_restart"});
            }
        }
        this.started = true;
        var that = this;
        return $.Deferred(function(pDefer){
            api_async.hub.start_from_whitepaper_with_files_and_variables(that.whitepaper, that.inputname, [], that.inputparameters,
                function(pResults) {
                    that.workableID = pResults.workable_id;
                    that.jacketID = pResults.jacket_id;
                    pDefer.resolve({
                        workableID: that.workableID,
                        jacketID: that.jacketID
                    });
                },
                function(pError){
                    console.error(pError);
                    pDefer.reject(pError);
            });
        });
    },
    
    /**
     * @description Run a httpService. this will run a script on the server and will return directly. This does not requires workers or workables
     * @function
     * @name WhitepaperConnection#httpService
     * @return {jqXHR}
     */
    httpService: function() {
        // encode to be able to pass a '#' charater
        return $.ajax({
            url: "/portal.cgi?http_service=" + encodeURIComponent(this.inputname) + "&whitepaper=" + encodeURIComponent(this.whitepaper),
            data: JSON.stringify(this.inputparameters),
            contentType: "text/plain; charset=utf-8",
            success: "json",
            dataType: "json",
            jsonp: false,
            type: "post"
        });
    },
    
    /**
     * @description If a workable is already fired, try to catch up and follow that workable.
     * So no need to create a second workable
     * @function
     * @name WhitepaperConnection#catchup
     * @param {Object} pJacketSettings containing workable_id and jacket_id
     * @return {Deferred}
     * @example whitepaperConnection.catchup({
     *      workable_id: "581afff771bc000000000005",
     *      jacket_id: "631ae550e0f1192dee5464f5",
     * });
     */
    catchup: function(pJacketSettings) {
        this.workableID = pJacketSettings.workable_id;
        this.jacketID = pJacketSettings.jacket_id;
        var that = this;
        return $.Deferred(function(pDefer){
            that._polling(pDefer);
        });
    }
};

/**
 * @description Is there a whitepaper with this specific name?
 * @function
 * @name WhitepaperConnection.exist
 * @param {String} pWhitepaper the name of the workflow
 * @param {String} pInputName the start from kiosk
 * @returns {Deferred}
 */
WhitepaperConnection.exist = function(pWhitepaper, pInputName){
    return $.Deferred(function(pDefer){
        api_async.hub.get_whitepaper_input_options(pWhitepaper, pInputName, function(pResults){
            if ($.isPlainObject(pResults) && !$.isEmptyObject(pResults)) {
                pDefer.resolve(true); 
            } else {
                pDefer.resolve(false); 
            } 
        }, function(pError) {
            if (pError !== undefined && pError.error_code === "WhitePaper not found") {
                pDefer.resolve(false);
            } else if (pError !== undefined && pError.error_code === "Input not found in WhitePaper") {
                pDefer.resolve(false);
            } else {
                console.error(pError);
                // other errors;
                pDefer.resolve(false);
            }
        });
    });
};

// handle quitting page
// abort all running workables, if they are still running
if (window !== undefined) {
    var previousFunction =  window.onbeforeunload;
    window.onbeforeunload = function(event) {
        for (var i = 0; i < connections.length; i++) {
            if (connections[i].hasLongLive() === true) {
                continue;// do not kill long lived connections
            }
            connections[i].stop("quit_page");
        }
        if (previousFunction !== null) {
            return previousFunction(event);
        }
    };
}

module.exports = WhitepaperConnection;
