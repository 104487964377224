/**
 * @constructor CloudflowURL
 * @description Create an URL util object that handles dynamic relative paths. The asset must be situated in a file store
 * @param {String} relativeBasePath The relative path
 * @param {String} pPath The path of the asset, if undefined the relativeBasePath becomes the main path
 * @param {Boolean} pFallbackLocation Must we use fallbacks?
 * @return {nm$_CloudflowURL.CloudflowURL}
 */
function CloudflowURL(relativeBasePath, pPath, pFallbackLocation) {
    this.cloudflowBasePath = "";
    this.uploadBasePath = "";
    this.fallbackLocation = pFallbackLocation;
    if (pPath === undefined) {
        this.setPath(relativeBasePath);
        this._mergePaths();
    } else {
        this.setPath(pPath);
        this._setRelativeBasePath(relativeBasePath);
        this._mergePaths();
        this._normalise();
    }
};

/**
 * @description Create a CloudflowURL from a JSON
 * @function
 * @name CloudflowURL.fromJSON
 * @static
 * @param {Object} json 
 * @returns {CloudflowURL}
 */
CloudflowURL.fromJSON = function(json){
    return new CloudflowURL(json.cloudflowRelativeBasePath, json.path);
};

CloudflowURL.getFolderURL = function(pURL) {
    if (typeof pURL === "string") {
        return pURL.substring(0, pURL.lastIndexOf('/') + 1);
    } 
    return pURL;
};

/**
 * @description IS the URL a relative path or not
 * @function
 * @name CloudflowURL#isRelativePath
 * @static
 * @param {String} pURL The RUL to check
 * @returns {Boolean} true if url is relative, false otherwise
 */
CloudflowURL.isRelativePath = function(pURL){
    if (typeof pURL !== "string" || pURL.length <= 0) {
        throw new Error("parameter URL must be a not empty string");
    }
    if (pURL[0] === ".") {
        if (pURL.length === 1) {
            return true; // url is "."
        } else if (pURL[1] === "/") {
            return true; // url is "./"
        } else if (pURL[1] === ".") {
            if (pURL.length === 2) {
                return true; // url is ".."
            } else if (pURL[2] === "/") {
                return true; // url is "../"
            }
        }
    }
    return false;
};

CloudflowURL.prototype = {

    constructor: CloudflowURL,
    
    _setRelativeBasePath: function(pRelativeBasePath) {
        if (typeof pRelativeBasePath !== "string" || pRelativeBasePath.length === 0) {
            this.cloudflowBasePath = "";
            this.uploadBasePath = "";
            return;
        }
        // make folder of it
        if (pRelativeBasePath[pRelativeBasePath.length - 1] !== "/") {
            pRelativeBasePath += "/";
        }
        if (pRelativeBasePath.indexOf("cloudflow://") === 0) {
            this.cloudflowBasePath = pRelativeBasePath;
            this.uploadBasePath = "/portal.cgi/" + pRelativeBasePath.slice(12);
        } else if (pRelativeBasePath.indexOf("/portal.cgi/") === 0) {
            this.cloudflowBasePath = "cloudflow://" + pRelativeBasePath.slice(12);
            this.uploadBasePath = pRelativeBasePath;
        } else {
            // fallback
            this.cloudflowBasePath = pRelativeBasePath;
            this.uploadBasePath = pRelativeBasePath;
        }
    },
    
    setPath: function(pPath) {
        if (typeof pPath !== "string" || pPath.length <= 0) {
            throw new Error("parameter path must be a not empty string");
        }
        this.path = pPath;
    },
    
    _mergePaths: function(){
        if (this.path.indexOf("cloudflow://") === 0) {
            this.path = this.path.slice(12);
            this.cloudflowBasePath = "cloudflow://";
            this.uploadBasePath = "/portal.cgi/";
        } else if (this.path.indexOf("/portal.cgi/") === 0) {
            this.path = this.path.slice(12);
            this.cloudflowBasePath = "cloudflow://";
            this.uploadBasePath = "/portal.cgi/";
        } else if (this.path.indexOf("/portal.cgi?") === 0 || 
                          this.path.indexOf("http:") === 0 || 
                          this.path.indexOf("https:") === 0 ||
                          this.path.indexOf("mailto:") === 0) {
            // special case do not use relative base paths at all
            this.cloudflowBasePath = "";
            this.uploadBasePath = "";
        } else if (this.path[0] === "/" && this.cloudflowBasePath.length > 0) {
            // prevent double /
            this.path = this.path.slice(1);
        } else if (this.fallbackLocation === true && this.cloudflowBasePath === "" && this._getWindowLocation() !== undefined) {
            // get currect path
            var currentPath = this._getWindowLocation().pathname.substring(0, this._getWindowLocation().pathname.lastIndexOf('/') + 1);
            // check if we are in cloudflow
            if (currentPath.indexOf("/portal.cgi/") === 0) {
                this.cloudflowBasePath = "cloudflow://" + currentPath.slice(12);
                this.uploadBasePath = currentPath;
                if (this.path[0] === "/") {
                    // prevent double /
                    this.path = this.path.slice(1);
                }
            }
        }
    },

    _getWindowLocation: function(){
        return window.location; // put in different function to be able to overwrite this function for tests
    },

    _normalise: function(){
        if (this.path[0] === "." && this.path[1] === "/") {
            this.path = this.path.substring(2);
        }
        // for each ../ found in the beginning, remove a end part but not the first end part
        // make arrays, handle them, join them afterwards
        var folders = this.cloudflowBasePath.split('/');
        var folders_upload = this.uploadBasePath.split('/');
        var path_parts = this.path.split("../");
        var found;
        for (var i=0; i<path_parts.length; i++) {
            if (path_parts[i] !== "") {
                break; // if we encounter a not empty path, we are done, all starting "../" are handled
            }
            found = false;
            // from end to second element, prevent removing first part "cloudflow://"
            // only found remove both ../ and ending folder part of relative path
            for (var f=folders.length -1; f>=1; f--) {
                if (folders[f] !== "") {
                    folders.splice(f, 1);
                    folders_upload.splice(f, 1);
                    path_parts.shift();
                    i--; // first loop should start at good point, may be negative 
                    found = true; 
                    break;
                }
            }// break if relative path is not big enouph
            if (found === false) {
                break;
            }
        }
        this.cloudflowBasePath = folders.join("/");
        this.uploadBasePath = folders_upload.join("/");
        this.path = path_parts.join("../");
    },

    /**
     * @description Get a url starting with cloudflow:// and if needed encounter the relative path with it
     * @function
     * @name CloudflowURL#getCloudflowPath
     * @returns {String} The url strating with cloudflow://
     */
    getCloudflowPath: function() {
        return this.cloudflowBasePath + this.path;
    },
    
    /**
     * @description Get a url starting with /portal.cgi/ and if needed encounter the relative path with it. Use this if you want to use this
     * url direclty in a custom HTML page
     * @function
     * @name CloudflowURL#getUploadPath
     * @returns {String} The url strating with /portal.cgi/
     */
    getUploadPath: function(){
        return this.uploadBasePath + this.path;
    },
    
    /**
     * @description Get the relative path of it
     * @function
     * @name CloudflowURL#getRelativeUploadPath
     * @param {String} pURL the url of the starting point
     * @returns {String} The relative path or the complete upload path if it is not inside the scope
     */
    getRelativeUploadPath: function(pURL) {
        var rootPoint;
        if (typeof pURL === "string" && pURL.length > 0) {
            rootPoint = (new CloudflowURL(pURL)).getUploadPath();
        } else if (pURL instanceof CloudflowURL) {
            rootPoint = pURL.getUploadPath();
        } else {
            return this.getUploadPath();
        }
        var currentPath = this.getUploadPath();
        if (CloudflowURL.isRelativePath(currentPath)) {
            return currentPath;
        }
        var sameFolderIndex = -1;
        for (var i=0; i<rootPoint.length; i++) {
            if (rootPoint[i] === "/") {
                sameFolderIndex = i;
            }
            if (rootPoint[i] !== currentPath[i]){
                break;
            }
        }
        if (sameFolderIndex === -1) {
            return this.getUploadPath();
        }
        var relativePath = currentPath.substring(sameFolderIndex + 1);
        var folderFound = false;
        for (var i=sameFolderIndex + 1; i<rootPoint.length; i++) {
            if (rootPoint[i] === "/") {
                folderFound = true;
                relativePath = "../" + relativePath;
            }
        }
        if (folderFound === false) {
            return "./" + relativePath;
        } else {
            return relativePath;
        }
    },

    toJSON: function(){
        return {
            cloudflowRelativeBasePath: this.cloudflowBasePath,
            path: this.path
        };
    }
};

module.exports = CloudflowURL;
