var CloudflowObject = require('../../CloudflowUtil/js/CloudflowObject.js');
var QueryPredefinedKeys = require('../../CloudflowUtil/js/QueryPredefinedKeys.js');

/**
 * @constructor Query
 * @name Query
 * @version 1
 * @description This class present a Query. This gives some help functionality to work with querys with a cloudflow API-interface.
 * @param {Array} pQuery - The query array that will be the main query array. 
 */
var Query = function (pQuery, pSearchQuery) {
    var errorMessage = Query.hasGoodInterface(pQuery);
    if (errorMessage !== true) {
        throw new Error(errorMessage);
    }
    // make a copy of the array
    this.query = Query.surroundBrack(pQuery);
    this.searchQuery = [];
    this.selectionQuery = [];
    if (Array.isArray(pSearchQuery)) {
        this.setSearchQuery(pSearchQuery);
    }
};

/**
* @function
* @desc this function controls if the query has a good interface.
* it controls if it is a array of strings|numbers|booleans.
* it does not control the logic itself. The query can be malformed.
* @static
* @name Query.hasGoodInterface
* @param {array} pQuery
* @returns {boolean_or_String} boolean true if the array has a good interface or a string with the explication why the query is bad.
*/
Query.hasGoodInterface = function (pQuery) {
    if (Array.isArray(pQuery)) {
        for (var i = 0; i < pQuery.length; i++) {
            if (Array.isArray(pQuery[i])) {
                if (pQuery[i-1] !== "in") {
                    return 'an array inside a query should be precended by the "in" operator';
                }
            } else if (typeof pQuery[i] !== "string" &&
                    typeof pQuery[i] !== "number" &&
                    typeof pQuery[i] !== "boolean") {
                return 'All elements of the query ' + pQuery + 'must be a string/number or a boolean';
            }
        }
        return true;
    } else {
        return 'Query ' + pQuery + 'must be a array';
    }
};

/**
 * @function
 * @description Control the input parameter array, if it is a good candidate for sortBy query.
 * @name Query.hasGoodOrderByQueryInterface
 * @static
 * @param {array} pSortByQuery The query to control
 * @param {array} pSortingKeys (optional) the array containgings the only possible sortings key 
 * @returns {boolean_or_String|String|Boolean} retruns the error string or true if interface is good.
 */
Query.hasGoodOrderByQueryInterface = function(pSortByQuery, pSortingKeys) {
    var basicCheckValue = Query.hasGoodInterface(pSortByQuery);
    if (typeof basicCheckValue === "string") {
        return basicCheckValue;
    } 
    // control if items are even
    if (pSortByQuery.length % 2 !== 0) {
        return 'the sortBy query must have even amount of items';
    }
    // loop over and each odd must be "ascending" or "descending"
    for (var i=1; i<pSortByQuery.length; i=i+2) {
        if (['ascending', 'descending'].indexOf(pSortByQuery[i]) < 0) {
            return "item at index " + i + " must be 'ascending' or 'descending'";
        }
    }
    // control the sorting keys if there are given!
    if (Array.isArray(pSortingKeys) && pSortingKeys.length > 0) {
        // loop over each even item and this must be a valid sorting key 
        for (var i=0; i<pSortByQuery.length; i=i+2) {
            if (pSortingKeys.indexOf(pSortByQuery[i]) < 0) {
                return "item at index " + i + " must be a valid sorting key";
            }
        }
    }
    return true;
};

/**
* @function
* @description Change the query, so it will be sorted  on the specific column
* @name Query.sortByColumn
* @static
* @param {String} pColumn the columnname where we must sort on
* @param {Boolean} pAsc true to sort on ascending, false to sort descending
* @returns {array | query} the changed query where we add the command to sort on that Column
*/
Query.getQueryDataSortByColumn = function (pColumn, pAsc) {
    if (typeof pColumn !== "string" || pColumn.length <= 0) {
        throw new Error('pColumn must be a string');
    }
    if (typeof pAsc !== "boolean") {
        throw new Error('pAsc must be a boolean');
    }
    var sorting = pAsc ? "ascending" : "descending";

    return [pColumn, sorting];
};

/**
 * @desc this functions concats two querys
 * @function
 * @name Query.concatQuery
 * @static
 * @param {Array} pQueryA the query that becomes before
 * @param {Array} pQueryB the query that becomes afterwards
 * @returns {Array} returns a query that is the concatanation of the two
 */
Query.concatQuery = function(pQueryA, pQueryB) {
    if (Query.hasGoodInterface(pQueryA) === true && Query.hasGoodInterface(pQueryA) === true) {
        if (pQueryA.length === 0) {
            return pQueryB;
        } else if (pQueryB.length === 0) {
            return pQueryA;
        } else {
            return Query.surroundBrack(pQueryA.slice()).concat(['and']).concat(Query.surroundBrack(pQueryB.slice()));
        }
    } else {
        throw new Error(Query.hasGoodInterface(pQueryA) + Query.hasGoodInterface(pQueryA));
    }
};


Query.surroundBrack = function (pQuery) {
    var query = pQuery.slice();
    if (query.length > 0) {
        query.push(')');
        query.unshift('(');
    }
    return query;
};

/**
 * @description filter statically the rows, designed for table component
 * @function
 * @name Query.apply
 * @static
 * @param {Array.<Object>} pRows All the rows, to be filtered
 * @param {Array.<String>} pSearchQuery A backend like query, limmitted by what the table generete
 * @param {Array.<String>} pOrderBy The array orberby, similar like the list_with_options call
 * @param {Number} pSkip the number to start of
 * @param {Number} pLimit the max amount of rows to retrun
 * @param {Number} pLast The number last of
 * @returns Array The filtered and sorted rows  
 */
Query.apply = function(pRows, pSearchQuery, pOrderBy, pSkip, pLimit, pLast) {
    if (!Array.isArray(pRows)) {
        throw new Error('parameter pRows must be an array');
    }
    // short-cut for empty rows
    if (pRows.length === 0) {
        return [];
    }
    // control pSearchQuery
    var errorMessage = Query.hasGoodInterface(pSearchQuery);
    if (errorMessage !== true) {
        throw new Error(errorMessage);
    }
    // control pOrderByquery
    if (pOrderBy === undefined) {
        pOrderBy = [];
    }
    errorMessage = Query.hasGoodOrderByQueryInterface(pOrderBy);
    if (errorMessage !== true) {
        throw new Error(errorMessage);
    } 
    // control pSkip
    if (pSkip === undefined) {
        pSkip = 0;
    } else if (typeof pSkip !== "number" || pSkip < 0) {
        throw new Error('parameter pSkip must be a positive number');
    }
    // control pLimit
    if (pLimit === undefined || pLimit === null) {
        pLimit = Number.MAX_SAFE_INTEGER || Number.MAX_VALUE || 10000000000;
    } else if (typeof pLimit !== "number" || pLimit < 0) {
        throw new Error('parameter pLimit must be a positive number');
    }
    function getField(pParent, pField) {
        return CloudflowObject.getParameter(pParent, pField);
    }
    // apply search, filter first
    // we suppose only "and"s are used !!!
    // we ony support querys of form >>>> ( a eq b) and ( c eq f ) and ( v eq s )
    // groups of 5 elements devided by ands
    if (Array.isArray(pSearchQuery) && pSearchQuery.length > 0) {
        // analyse query
        var rows = [];
        var searchRestriction = [];
        for (var i = 1; i < pSearchQuery.length; i++) {
            if ((i + 1) % 6 === 0 && pSearchQuery[i] !== "and") {
                // sub querys must be clued by and's
                throw new Error('there is no support for this kind of search query, sub querys must be clued by ands');
            } else if (pSearchQuery[i] === "contains text like") { 
                // make analyse model object
                // make 'value' a "string" by adding "" before
                searchRestriction.push({field: pSearchQuery[i - 1], value: "" + pSearchQuery[i + 1], compareType: "contains text like"});
            } else if (pSearchQuery[i] === "contains") { 
                // make analyse model object
                // make 'value' a "string" by adding "" before
                searchRestriction.push({field: pSearchQuery[i - 1], value: "" + pSearchQuery[i + 1], compareType: "contains"});
            } else if (["equal to", "greater than", "greater than or equal to", "less than", "less than or equal to"].indexOf(pSearchQuery[i]) >= 0) {
                searchRestriction.push({field: pSearchQuery[i - 1], value: pSearchQuery[i + 1], compareType: pSearchQuery[i]});
            } else if (pSearchQuery[i] === "in") {
                searchRestriction.push({field: pSearchQuery[i - 1], value: pSearchQuery[i + 1], compareType: "in"})
            }
        }
        // control supported query
        // there are groups of 6 ( two breackes, one equation, two values and one "and")
        if ((pSearchQuery.length + 1) / 6 !== searchRestriction.length) {
            throw new Error('there is no support for this kind of search query');
        }
        // apply query
        for (var i = 0; i < pRows.length; i++) {
            var match = true;
            for (var j = 0; j < searchRestriction.length; j++) {
                // change to uppercase because "contains text like" is case free, undependend of uppercase, lowercase
                var searchValue = getField(pRows[i], searchRestriction[j].field);
                if (searchRestriction[j].compareType === "contains text like") { 
                    if (typeof searchValue !== "string" || searchValue.toUpperCase().indexOf(searchRestriction[j].value.toUpperCase()) === -1) {
                        match = false;
                        break;
                    }
                }
                if (searchRestriction[j].compareType === "contains") { 
                    if (typeof searchValue !== "string" || searchValue.indexOf(searchRestriction[j].value) === -1) {
                        match = false;
                        break;
                    }
                }
                if (searchRestriction[j].compareType === "greater than" && searchValue <= searchRestriction[j].value) {
                    match = false;
                    break;
                }
                if (searchRestriction[j].compareType === "greater than or equal to" && searchValue < searchRestriction[j].value) {
                    match = false;
                    break;
                }
                if (searchRestriction[j].compareType === "less than" && searchValue >= searchRestriction[j].value) {
                    match = false;
                    break;
                }
                if (searchRestriction[j].compareType === "less than or equal to" && searchValue > searchRestriction[j].value) {
                    match = false;
                    break;
                }
                if (searchRestriction[j].compareType === "equal to" && searchValue !== searchRestriction[j].value) {
                    match = false;
                    break;
                }
                if (searchRestriction[j].compareType === "in" && searchRestriction[j].value.includes(searchValue) === false) {
                    match = false;
                    break;
                }
            }
            if (match) {
                rows.push(pRows[i]);
            }
        }
        pRows = rows;
    }

    pRows = Query.orderBy(pRows, pOrderBy)
    
    // clip results
    if (typeof pLast === "number") {
        pRows = pRows.slice(pSkip, pLast);
        pRows = pRows.slice(0, pLimit);
        return pRows;
    }
    pRows = pRows.slice(pSkip, pSkip + pLimit);
    return pRows;
};

Query.orderBy = function(pRows, pOrderBy) {
    function getField(pParent, pField) {
        return CloudflowObject.getParameter(pParent, pField);
    }
    // apply orderBy
    if (Array.isArray(pOrderBy) && pOrderBy.length > 0) {
        if (pOrderBy.length === 2) {
            var field = pOrderBy[0];
            var ascending = (pOrderBy[1] === "ascending") ? 1 : -1;
            pRows.sort(function (a, b) {
                var fieldA = getField(a, field);
                // all undefineds go up, before
                if (fieldA === undefined) {
                    return -1 * ascending;
                }
                var fieldB = getField(b, field);
                if (fieldB === undefined) {
                    return 1 * ascending;
                }
                if (typeof fieldA === "number" && typeof fieldB === "number") {
                    return fieldA > fieldB ? ascending : -ascending;
                }
                // make of a[field] a string
                // b[field] may be a number
                return ("" + getField(a, field)).localeCompare(getField(b, field)) * ascending;
            });
        } else if (pOrderBy.length === 4) {
            var field = pOrderBy[0];
            var field2 = pOrderBy[2];
            var ascending = (pOrderBy[1] === "ascending") ? 1 : -1;
            var ascending2 = (pOrderBy[3] === "ascending") ? 1 : -1;
            pRows.sort(function (a, b) {
                // all undefineds go up, before
                if (getField(a, field) === undefined) {
                    return -1 * ascending;
                }
                if (getField(b, field) === undefined) {
                    return 1 * ascending;
                }
                // make of a[field] a string
                // b[field] may be a number
                var firstCompare = ("" + getField(a, field)).localeCompare(getField(b, field)) * ascending;
                if (firstCompare === 0) {
                    // all undefineds go up, before
                    if (getField(a, field2) === undefined) {
                        return -1 * ascending2;
                    }
                    if (getField(b, field2) === undefined) {
                        return 1 * ascending2;
                    }
                    return ("" + getField(a, field2)).localeCompare(getField(b, field2)) * ascending2;
                } else {
                    return firstCompare;
                }
            });
        } else {
            throw new Error('there is no support for multiple order by');
        }
    }

    return pRows;
}

var dateCalculations = new RegExp("([+-]) *([0-9]+) *([DMH])");
function _dateCalc(date, calcString) {
    calcString.replace(dateCalculations, function(match, p1, p2, p3){
        switch(p3) {
            case 'H':
                if (p1 == '+') {
                    date.setHours(date.getHours() + parseInt(p2))
                } else {
                    date.setHours(date.getHours() - parseInt(p2))
                }
                break;
            case 'D':
                if (p1 == '+') {
                    date.setDate(date.getDate() + parseInt(p2))
                } else {
                    date.setDate(date.getDate() - parseInt(p2))
                }
                break;
            case 'M':
                if (p1 == '+') {
                    date.setMonth(date.getMonth() + parseInt(p2))
                } else {
                    date.setMonth(date.getMonth() - parseInt(p2))
                }
                break;
        }
        return '';
    })
    return date;
}
Query.prepare = function(pRows){
    if (Array.isArray(pRows) === false) {
        throw new Error("parameter pRows must be an array");
    }
    var rows = pRows.slice(), today, now;
    for (var i=0; i<rows.length; i++) {
        if (typeof rows[i] === "string" && rows[i][0] === "$") {
            // calc today and use as cache, prevent multiple different dates in one query
            now = now || new Date(); 
            if (today === undefined) {
                today = new Date();
                today.setHours(0);
                today.setMinutes(0);
                today.setSeconds(0);
                today.setMilliseconds(0);
            } 
            if (rows[i].indexOf(QueryPredefinedKeys.ISO_NOW) === 0){
                var date = new Date(+now); // clone date
                var rest = rows[i].substring(QueryPredefinedKeys.ISO_NOW.length);
                if (rest.length > 0) {
                    date = _dateCalc(date, rest);
                }
                // ISO date none extended
                rows[i] = date.toISOString().substring(0, 19) + "Z";
            } else if (rows[i].indexOf(QueryPredefinedKeys.UNIX_NOW) === 0) {
                var date = new Date(+now); // clone date
                var rest = rows[i].substring(QueryPredefinedKeys.UNIX_NOW.length);
                if (rest.length > 0) {
                    date = _dateCalc(date, rest);
                }
                rows[i] = parseInt(+date / 1000);
            }  else if (rows[i].indexOf(QueryPredefinedKeys.UNIXMILLISEC_NOW) === 0) {
                var date = new Date(+now); // clone date
                var rest = rows[i].substring(QueryPredefinedKeys.UNIXMILLISEC_NOW.length);
                if (rest.length > 0) {
                    date = _dateCalc(date, rest);
                }
                rows[i] = +date;
            } else if (rows[i].indexOf(QueryPredefinedKeys.ISO_TODAY) === 0){
                var date = new Date(+today); // clone date
                var rest = rows[i].substring(QueryPredefinedKeys.ISO_TODAY.length);
                if (rest.length > 0) {
                    date = _dateCalc(date, rest);
                }
                // ISO date none extended
                rows[i] = date.toISOString().substring(0, 19) + "Z";
            } else if (rows[i].indexOf(QueryPredefinedKeys.UNIX_TODAY) === 0) {
                var date = new Date(+today); // clone date
                var rest = rows[i].substring(QueryPredefinedKeys.UNIX_TODAY.length);
                if (rest.length > 0) {
                    date = _dateCalc(date, rest);
                }
                rows[i] = parseInt(+date / 1000);
            }  else if (rows[i].indexOf(QueryPredefinedKeys.UNIXMILLISEC_TODAY) === 0) {
                var date = new Date(+today); // clone date
                var rest = rows[i].substring(QueryPredefinedKeys.UNIXMILLISEC_TODAY.length);
                if (rest.length > 0) {
                    date = _dateCalc(date, rest);
                }
                rows[i] = +date;
            }
        }
    }
    return rows;
}
    
Query.prototype = {

        constructor: Query,
        
        /**
         * @description Get the main start query, not the search query
         * @function
         * @name Query#getMainQuery
         * @returns {Array.<String>}
         */
        getMainQuery: function(){
            return this.query.slice();
        },

        /**
         * @description Get the search query in array format. 
         * This is not the main query, but only the search query.
         * @function
         * @name Query#getSearchQuery
         * @returns {Array} - the search query
         */
        getSearchQuery: function() {
            return this.searchQuery;
        },
        
        /**
         * @description Replace the current search query to the query from the inputparameter.
         * The main query stay untouched.
         * @function
         * @name Query#setSearchQuery
         * @throws {type} if the inputparameter pQuery is not a well formed query array
         * @param {array} pQuery - the search query
         * @returns {undefined}
         */
        setSearchQuery: function(pQuery) {
            var errorMessage = Query.hasGoodInterface(pQuery);
            if (errorMessage !== true){
                throw new Error(errorMessage);
            }
            this.searchQuery = pQuery.slice();
        },
        
        /**
         * @description is the quesry the same or not?
         * @function
         * @name Query#isSameSearchQuery 
         * @param {Array} pQuery 
         * @returns Boolean true if query is the same
         */
        isSameSearchQuery: function(pQuery) {
            if (this.searchQuery.length !== pQuery.length) {
                return false;
            }
            for(var i=0; i<this.searchQuery.length; i++) {
                if (this.searchQuery[i] !== pQuery[i]) {
                    return false;
                }
            }
            return true;
        },

        getSelectionQuery: function(){
            return this.selectionQuery.slice();
        },

        setSelectionQuery: function(pQuery) {
            var errorMessage = Query.hasGoodInterface(pQuery);
            if (errorMessage !== true){
                throw new Error(errorMessage);
            }
            this.selectionQuery = pQuery.slice();
        },

        /**
         * @description Concat a query array at the end of the main query.
         * The searchquery stays untouched.
         * @function
         * @name Query#concat 
         * @param {Array} pQuery the query array to concat add the main query
         * @returns {nixps-cloudflow-Query_L8.Query.prototype}
         */
        concat: function(pQuery) {
            this.query = Query.concatQuery(this.query, pQuery);
            return this;
        },
        
        /**
         * @description Returns the query in array format. This is a concatenation between the main query and the search query
         * @function
         * @name Query#getQuery
         * @returns {array}
         */
        getQuery: function(){
            if (this.selectionQuery.length === 0) {
                return Query.concatQuery(this.query, this.searchQuery);
            } else {
                return Query.concatQuery(this.selectionQuery, this.searchQuery);
            }
        } 
};

module.exports = Query;

if (window.Query === undefined) {
    window.Query = Query;
}