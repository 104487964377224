"use strict";
var Context = require("../../../pagebuilder/js/Context.js");

var BadgeNumberUtil = {
    parse: function(pValue){
        if (typeof pValue === "number" && pValue > 0) {
            return pValue;
        } else if (Array.isArray(pValue) && pValue.length > 0) {
            return pValue.length;
        } else if (pValue && typeof pValue.count === "number" && pValue.count > 0) {
            return pValue.count;
        } else if (pValue && Array.isArray(pValue.results || pValue.documents) && (pValue.results || pValue.documents).length > 0) {
            return (pValue.results || pValue.documents).length;
        } else if (typeof pValue === "string" && pValue.length > 0) {
            var integer = parseInt(pValue);
            if (integer > 0) {
                return integer;
            }
        } else if (pValue && typeof pValue.connector === "string") {
            var that = this;
            return $.when(Context.fromJSON(pValue).loadDataFromCache({suppressRedirect: true})).then(function(pResult){
                return that.parse(pResult);
            });
        } else if (typeof pValue === "function") {
            return this.parse(pValue());
        }
        return null;
    }
}

module.exports = BadgeNumberUtil;