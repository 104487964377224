"use strict"
// require("./pwa-fixer.js");
var BadgeNumberUtil = require("./BadgeNumberUtil.js");

// private parameters
var _isOnLine = true;
var _serviceWorkerRegistration = undefined;
var _updateSWTimeoutID = null;
var _messageListeners = [];
var _error = null;
var _energySaving = null;

/**
 * @static
 * @constructor Device
 * @description An util function to retrieve all information about the device the page is running in
 */
var Device = {
    /**
     * @description run init code, set the theme color if needed
     * @function
     * @name Device.init
     */
    init: function(pOptions){
        var options = Object.assign({
            mainColorVariableName: "--cloudflow-main",
            serviceWorkerURL: undefined,
            serviceWorkerScope: undefined,
            appBadgeConfiguration: undefined
        }, pOptions || {});

        // add a default theme-color, the background color of browser headers, if supported (Safari 15) 
        if (typeof window.getComputedStyle === "function" && typeof document.querySelector === "function" && document.querySelector("meta[name='theme-color']") === null){
            var color = getComputedStyle(document.documentElement).getPropertyValue(options.mainColorVariableName);
            if (typeof color === "string" && color.length >= 4 && typeof color.trim === "function") { // some pages does not have custom css variables
                var metaTag  = document.createElement("META");
                metaTag.setAttribute("name", "theme-color");
                metaTag.setAttribute("content", color.trim());
                document.head.appendChild(metaTag);
            }
        }

        if (window.origin !== "null" && typeof options.serviceWorkerURL === "string" && options.serviceWorkerURL.length > 0 && window.navigator !== undefined && "serviceWorker" in window.navigator) {
            clearTimeout(_updateSWTimeoutID);
            var url = options.serviceWorkerURL;
            if (options.serviceWorkerURL.indexOf("cloudflow://") === 0) {
                url = "/portal.cgi/" + options.serviceWorkerURL.slice(12);
            }
            var parameters = undefined;
            if (typeof parameters === "object") {
                url += url.indexOf("?") >= 0 ? "&" : "?";
                for (var name in parameters) {
                    if (["string", "number", "boolean"].includes(typeof parameters[name])) {
                        url += (name + "=" + encodeURIComponent(parameters[name]) + "&");
                    }
                }
            }
            navigator.serviceWorker.register(url, { scope: options.serviceWorkerScope, updateViaCache: "none" })
            .then(function(registration){
                // registration worked
                _serviceWorkerRegistration = registration;

                _updateSWTimeoutID = setTimeout(function(){
                    if (_serviceWorkerRegistration && _serviceWorkerRegistration.update) {
                        _serviceWorkerRegistration.update();
                    }
                }, 43200000); // 12 h
            })
            .catch(function(pError){
                _serviceWorkerRegistration = null;
                console.error(pError);
            });
            navigator.serviceWorker.addEventListener("message", function(pEvent) {
                _messageListeners.forEach(function(pCallBackFunction){
                    pCallBackFunction(pEvent.data);
                })
            });
        }

        if (options.appBadgeConfiguration) {
            this.setAppBadge(options.appBadgeConfiguration);
        }
    },

    /**
     * @description Set or remove the app badgenumber. It is the badge number a user see above the installed app icon in the operation system
     * @function
     * @name Device.setAppBadge
     * @param {Mixed} pConfiguration the information to retreive the correct badge number
     */
    setAppBadge: function(pConfiguration){
        if (window.navigator !== undefined && typeof navigator.setAppBadge === "function") {
            Promise.all([BadgeNumberUtil.parse(pConfiguration)]).then(function(pBadgeNumbers){
                if (Array.isArray(pBadgeNumbers) && pBadgeNumbers[0] !== null) {
                    navigator.setAppBadge(pBadgeNumbers[0]);
                } else {
                    navigator.clearAppBadge();
                }
            })
        }
    },

    postMessage: function(pType, pData) {
        if (typeof pType !== "string" || pType.length <= 0) {
            throw new Error("parameter pType must be a not empty string");
        }
        if (!window.navigator || navigator.serviceWorker === undefined) {
            // old browser
            return null;
        }
        return navigator.serviceWorker.ready.then(function(pRegistration) {
            if (pRegistration !== undefined && pRegistration.active !== undefined) {
                pRegistration.active.postMessage({
                    type: pType, 
                    data: pData
                });
            } else {
                throw new Error("registration has wrong interface");
            }
        })
    },

    addPostMessageListener: function(pCallBackFunction){
        if (typeof pCallBackFunction !== "function") {
            throw new Error("parameter pCallBackFunction must be a function");
        }
        _messageListeners.push(pCallBackFunction);
    },

    removePostMessageListener: function(pCallBackFunction){
        if (typeof pCallBackFunction !== "function") {
            throw new Error("parameter pCallBackFunction must be a function");
        }
        var found = false;
        for(var i=_messageListeners.length-1; i>=0; i--) {
            if (_messageListeners[i] === pCallBackFunction) {
                _messageListeners.splice(i,1);
                found = true;
            }
        }
        return found;
    },

    /**
     * @description Inform everybody that we have an error
     * @function
     * @name Device.bringInError
     * @param {Object} pError error object   
     */
    bringInError: function(pError) {
        _error = pError;
    },

    /**
     * @description Must we continue polling or better skip one loop
     * @function
     * @name Device.continuePolling
     * @param {Number} pWidgetUUID 
     * @returns {Boolean} true if the polling may happend, false if you better skip the polling     
     */
    continuePolling: function(pWidgetUUID) {
        return document.hidden !== true && _isOnLine === true;
    },

    /**
     * @description Get the factor to multiply on the polling, With this we can delay the polling frequentie
     * @function
     * @name Device.getPollingFactor
     * @param {Number} pWidgetUUID 
     * @returns {Boolean} true if the polling may happend, false if you better skip the polling     
     */
    getPollingFactor: function(pWidgetUUID) {
        if (_error !== null && ["http_errorcode_503", "http_errorcode_504"].includes(_error.error_code)) {
            return 1.3;
        } else if (_energySaving === true) {
            return 1.25;
        }
        return 1;
    },

    /**
     * @description Check if we are online
     * @function
     * @name Device.isOnline
     * @returns {Boolean} true if we are online, false if we are offline
     */
    isOnline: function(){
        return _isOnLine;
    },

    /**
     * @description check if the device is a mobile device or not
     * @function
     * @name Device.hasMobileWidth
     * @returns {Boolean} true if the device is mobile
     */
    hasMobileWidth: function(){
        if (window.navigator.userAgent.toLowerCase().includes("mobi")) {
            return true;
        }
        if (Math.max(screen.availHeight, screen.availWidth) > 1200) {
            return false;
        }
        if (screen.availHeight < screen.availWidth) {
            // landscape
            return $(window).height() < 431;
        } else {
            // portrait
            return $(window).width() < 576;
        }
    },

    /**
     * @description Check if the device is an iOS (Apple) device or not
     * @function
     * @name Device.iSIOS
     * @returns Boolean true if the mobile is an iOS device
     */
    isIOS: function(){
        return ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    },

    /**
     * @description Are we in eco modus or not?
     * @function
     * @name Device.isEnerySaveMode
     * @returns {Boolean} true if we need to save energy, false otherwise
     */
    isEnerySaveMode: function(){
        return _energySaving === true;
    },

    destroy: function(){
        clearTimeout(_updateSWTimeoutID);
        _error = null;
        _serviceWorkerRegistration = undefined;
        _messageListeners = [];
        _energySaving = null;
    }
}

if (window.navigator !== undefined && navigator.onLine === false) {
    // strange it is offline from start
    _isOnLine = false; 
    if (document.body) {
        document.body.classList.add("cf-offline");
    }
}

// switch to online, when online event is triggered
window.addEventListener("online", function(){
    _isOnLine = true;
    if (document.body) { // make sure the body is present
        document.body.classList.remove("cf-offline");
    }
})

// switch to offline, when offline event is triggered
window.addEventListener("offline", function(){
    _isOnLine = false;
    if (document.body) { // make sure the body is present
        document.body.classList.add("cf-offline");
    }
})

// logic to handle energy saving
// it is like the iPhone where you can activate the erergy saving mode. (The battery icon becomes yellow on the iPhone)
// when the device has a battery less then 9% who is not charging, switch the ergery saving mode on
// When the battery is bigger the 30% in saving mode, the mode will be disabled
if (window.navigator !== undefined && typeof navigator.getBattery === "function") {
    navigator.getBattery().then(function(battery) {
        if (typeof battery.level === "number" && battery.level < 0.09 && battery.charging !== true) {
            _energySaving = true; // start with low battery
        }
        battery.onlevelchange = function(pEvent) {
            if (typeof battery.level === "number" && battery.level >= 0 && battery.level <= 1) {
                if (battery.level < 0.09 && battery.charging !== true) {
                    _energySaving = true;
                } else if (battery.level > 0.3 && _energySaving === true) {
                    _energySaving = null;
                }
            }
        }
        battery.onchargingchange = function(pEvent){
            if (battery.charging === true) {
                if (_energySaving === true) {
                    _energySaving = null;
                }
            } else if (battery.charging === false) {
                if (typeof battery.level === "number" && battery.level < 0.09) {
                    _energySaving = true;
                }
            }
        }
    })
}
module.exports = Device;