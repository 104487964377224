function s4() {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
};


var GUID = {
    /**
     * @description generate a UUID
     * @function
     * @name GUID.generate
     * @returns a string containing an uuid 
     */
    generate: function() {
        return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
    }

}

module.exports = GUID;