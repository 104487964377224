
function ContextStorage() {
    
};

ContextStorage.isSupport = function() {
    return storageAvailable('localStorage');
};

ContextStorage.write = function(pObjectToSave, pUUID, pContextID, pRemoveOldMilleSec, pRemoveLastReadMilleSec) {
    if (pContextID === undefined) {
        pContextID = "nixps.PageBuilder.ContextStorage";
    }
    if (pRemoveOldMilleSec === undefined) {
        pRemoveOldMilleSec = (365 * 24 * 3600 * 1000);
    }
    var storage = localStorage.getItem(pContextID);
    var storageArray;
    if (typeof storage !== "string" || storage.length <= 0) {
        storageArray = [];
    } else {
        try {
            storageArray = JSON.parse(storage);
        } catch(e) {
            console.error(e);
            storageArray = [];
        }
    }
    if (Array.isArray(storageArray) && storageArray.length > 0) {
        if (typeof pRemoveOldMilleSec === "number" && pRemoveOldMilleSec > 0) {
            var limitTimePoint = Date.now() - pRemoveOldMilleSec;
            storageArray = storageArray.filter(function(elem, index) {
                return elem !== undefined && typeof elem.timestamp === "number" && limitTimePoint < elem.timestamp;
            });
        }
        if (typeof pRemoveLastReadMilleSec === "number" && pRemoveLastReadMilleSec > 0) {
            var limitTimePoint = Date.now() - pRemoveLastReadMilleSec;
            storageArray = storageArray.filter(function(elem, index) {
                if (elem !== undefined) {
                    if (typeof elem.last_read === "number" && limitTimePoint < elem.last_read) {
                        return true;
                    } else if (typeof elem.timestamp === "number" && limitTimePoint < elem.timestamp) {
                        return true;
                    }
                }
                return false;  
            });
        }
    }
    var uuid;
    if (pUUID === undefined) {
        uuid = generateGUID();
    } else {
        uuid = pUUID;
    }
    storageArray.push({
        timestamp: Date.now(),
        uuid: uuid,
        value: pObjectToSave
    });
    // always surrender by try / catch
    // storage can be full
    try {
        localStorage.setItem(pContextID, JSON.stringify(storageArray));
    } catch(e) {
        console.error(e);
        if (typeof e.message === "string" && e.message.endsWith("exceeded the quota.")) {
            api_async.logging.trace.log("com.nixps.generic", "warn", "reached maximum storage quota on browser");
        }
        return null;
    }
    return uuid;
};

ContextStorage.update = function(pObjectToSave, pObjectUUID, pContextID, pRemoveOldMilleSec, pRemoveLastReadMilleSec) {
    if (pContextID === undefined) {
        pContextID = "nixps.PageBuilder.ContextStorage";
    }
    var storage = localStorage.getItem(pContextID);
    var storageArray;
    if (typeof storage !== "string" || storage.length <= 0) {
        storageArray = [];
    } else {
        try {
            storageArray = JSON.parse(storage);
        } catch(e) {
            console.error(e);
            storageArray = [];
        }
    }
    if (storageArray.length === 0) {
        return null;
    }
    var found = false;
    for (var i = 0; i<storageArray.length; i++) {
        if (storageArray[i].uuid === pObjectUUID) {
            storageArray[i].timestamp = Date.now();
            storageArray[i].value = pObjectToSave;
            found = true;
            break;
        }
    }
    if (found === false) {
        return null;
    }
    if (Array.isArray(storageArray) && storageArray.length > 0) {
        if (typeof pRemoveOldMilleSec === "number" && pRemoveOldMilleSec > 0) {
            var limitTimePoint = Date.now() - pRemoveOldMilleSec;
            storageArray = storageArray.filter(function(elem, index) {
                return elem !== undefined && typeof elem.timestamp === "number" && limitTimePoint < elem.timestamp; 
            });
        }
        if (typeof pRemoveLastReadMilleSec === "number" && pRemoveLastReadMilleSec > 0) {
            var limitTimePoint = Date.now() - pRemoveLastReadMilleSec;
            storageArray = storageArray.filter(function(elem, index) {
                if (elem !== undefined) {
                    if (typeof elem.last_read === "number" && limitTimePoint < elem.last_read) {
                        return true;
                    } else if (typeof elem.timestamp === "number" && limitTimePoint < elem.timestamp) {
                        return true;
                    }
                } 
                return false;
            });
        }
    }
    // always surrender by try / catch
    // storage can be full
    try {
        localStorage.setItem(pContextID, JSON.stringify(storageArray));
        return pObjectToSave;
    } catch(e) {
        console.error(e);
        if (typeof e.message === "string" && e.message.endsWith("exceeded the quota.")) {
            api_async.logging.trace.log("com.nixps.generic", "warn", "reached maximum storage quota on browser");
        }
        return null;
    }
};

ContextStorage.read = function(pUUID, pRemove, pContextID) {
    if (pContextID === undefined) {
        pContextID = "nixps.PageBuilder.ContextStorage";
    }
    if (pRemove === undefined) {
        pRemove = true;
    }
    var storage = localStorage.getItem(pContextID);
    // returns null is not exists
    if (storage === null || storage === undefined) {
        return undefined;
    }
    var storageArray;
    try {
        storageArray = JSON.parse(storage);
    } catch(e) {
        console.error(e);
        return undefined;
    }
    if (Array.isArray(storageArray) === false || storageArray.length <= 0) {
        return undefined;
    }
    var valueFound;
    if (pUUID === undefined) {
        valueFound = []
        for (var i=0; i<storageArray.length; i++) {
            valueFound.push(storageArray[i].value);
            if (pRemove !== true) {
                storageArray[i].last_read = Date.now();
            }
        }
        if (pRemove === true) {
            storageArray = [];
        }
    } else {
        for (var i=0; i<storageArray.length; i++) {
            if (storageArray[i].uuid === pUUID) {
                valueFound = storageArray[i].value;
                if (pRemove === true) {
                    storageArray.splice(i, 1);
                } else {
                    storageArray[i].last_read = Date.now();
                }
                break;
            }
        }
    }
    try {
        localStorage.setItem(pContextID, JSON.stringify(storageArray));
    } catch(e) {
        // failed to update....
        console.error(e);
    }
    return valueFound;
};

ContextStorage.remove = function(ID, pContextID) {
    try {
        return ContextStorage.read(ID, true, pContextID);
    } catch(e) {
        console.error(e);
    }
    return null;
};

ContextStorage.clear = function(pContextID) {
    try {
        localStorage.removeItem(pContextID);
    } catch(e) {
        console.error(e);
    }
};

ContextStorage.prototype = {

    constructor: ContextStorage

};

module.exports = ContextStorage;

function s4() {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
}

function generateGUID() {
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};

function storageAvailable(type) {
    try {
        var storage = window[type],
            x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    }
    catch(e) {
        return e instanceof DOMException && (
            // everything except Firefox
            e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === 'QuotaExceededError' ||
            // Firefox
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage.length !== 0;
    }
}