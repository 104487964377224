var CloudflowObject = require("../../cloudflow/CloudflowUtil/js/CloudflowObject.js");
var DatabaseConnection = require("../../cloudflow/Table/js/DatabaseConnection.js");
var WhitepaperConnection = require("../../cloudflow/CloudflowUtil/js/WhitepaperConnection.js");
var FileConnection = require("../../cloudflow/CloudflowUtil/js/FileConnection.js");
var Storage = require("../../cloudflow/CloudflowUtil/js/ContextStorage.js");

var arrayURIsyntacs = new RegExp("^[^\\]]*\\[[0-9]+\\]$");

var _loadData = function(pCollectionName, pID) {
    // parameters will be checked by the DatabaseCollection object
    return $.Deferred(function(pDefer){
        if (typeof pID === "string") {
            DatabaseConnection.get(pCollectionName, pID, pDefer.resolve, function(pError){
                console.error(pError);
                pDefer.reject(pError);
            }); 
        } else if (Array.isArray(pID)) {
            DatabaseConnection.list_with_options(pCollectionName, pID, [], [], {first: 0, maximum: 1},  function(pResults){
                if (pResults !== undefined && Array.isArray(pResults.results)) {
                    if (pResults.results.length <= 0) {
                        console.error("no results found");
                        pDefer.reject("no results found");
                    } else {
                        pDefer.resolve(pResults.results[0]);
                    }
                } else {
                    console.error("wrong returning interface");
                    pDefer.reject({"error_code": "wrong_return_interface", "error": "wrong returning interface"});
                }
            }, function(pError) {
                console.error(pError);
                pDefer.reject(pError);
            });
        } else {
            pDefer.reject({"error_code": "wrong_id_interface", "error": "wrong id interface"});
        }
    });
};

/**
 * @constructor Context
 * @description Get a specific context specified by the parameters pContext and pContextKey.
 * The target is to get an javascript object containing usfull information. 
 * @param {String} pConnector Specifies which group of collection we must use.
 * @param {String} pCollection The key specifing the collection, database or the group/kind.
 * @param {String} pKey Which object must we take. This represents in most cases the _id of the collection
 * @return {nm$_Context.Context}
 */
var Context = function(pConnector, pCollection, pKey, pUseCache, pCache) {
    this.data = null; // set on null indicates that data is not yet loaded or failed to loaded
    this.debug = null;
    if (pConnector === "whitepaper" && typeof pKey === "string" && pKey.length > 11 && pKey.indexOf("workableid_") === 0) {
        var workableid = pKey.substring(11 /* length of "workableid_" */);
        this.setConnector(pConnector);
        this.setCollection(pCollection);
        this.setKey({"inputname": "dummyname", "variables": {}});
        this.currentWhitepaperConnections = [];
        this.fileConnections = [];
        this.error;
        this.setUseCache(true);
        this.setCache({"workable_id": workableid});
    } else {
        this.setConnector(pConnector);
        this.setCollection(pCollection);
        this.setKey(pKey);
        this.currentWhitepaperConnections = [];
        this.fileConnections = [];
        this.error;
        this.setUseCache(pUseCache);
        this.setCache(pCache);
    }
};

Context.fromJSON = function(pJSON) {
    if (pJSON === null || pJSON === undefined) {
        return new Context();
    } else {
        return new Context(pJSON.connector, pJSON.collection, pJSON.key, pJSON.useCache, pJSON.cache);
    }
};

/**
 * @description Get the context form the url parameters
 * @function
 * @param {Object} pContextJSONIfNothingDefined context used if nothing is defined
 * @static
 * @returns Context
 */
Context.fromURL = function(pContextJSONIfNothingDefined) {
    var urlParameters = {};
    if (window.URI !== undefined) {
        var url = new URI();
        urlParameters = url.query(true);
        // convert old arrays into new ones
        for (var parameter in urlParameters) {
            if (arrayURIsyntacs.test(parameter) === true) {
                var names = parameter.split("[");
                var arrayName = names[0];
                var arrayIndex = names[1].slice(0,-1);
                if (urlParameters[arrayName] === undefined) {
                    urlParameters[arrayName] = [];
                }
                urlParameters[arrayName][arrayIndex] = urlParameters[parameter];
                delete urlParameters[parameter];
            }
        }
    } else if (window.$ !== undefined && typeof $.url === "function") {
        // fallback for backward compatible old base urls who has modern custom page components
        urlParameters = $.url().param(); 
    } else {
        throw new Error("Context.fromURL could not parse url");
    }
    if (urlParameters.collection === null || 
            urlParameters.collection === "null" ||
            urlParameters.collection === undefined ||
            urlParameters.collection === "undefined") {
        return Context.fromJSON(pContextJSONIfNothingDefined);
    } 
    if (["object", "whitepaper", "startFromDBIO", "httpservice"].includes(urlParameters.connector) ) {
        var key;
        if (typeof urlParameters.id === "string" && urlParameters.id.length > 0) {
            // is data save localy?
            if (urlParameters.id.length > 11 && urlParameters.id.indexOf("workableid_") === 0) {
                var workableid = urlParameters.id.substring(11 /* length of "workableid_" */);
                return new Context(urlParameters.connector, urlParameters.collection, {"inputname": "dummyname", "variables": {}}, true, {"workable_id": workableid});
            } else if (urlParameters.id.indexOf("localstorage_") === 0) {
                key = Storage.read(urlParameters.id.substring(13 /* length of "localstorage_" */));
                // if not found, key will be undefined
                if (key === undefined) {
                    key = {}; // fall back
                    console.error("could not retrieve information from localstorage with key " + urlParameters.id);
                }
            } else {
            // data is passed by url itself
                key = JSON.parse(urlParameters.id);
            }
        } else if ($.isPlainObject(urlParameters.id)) {
            key = urlParameters.id; 
        }
        return new Context(urlParameters.connector, urlParameters.collection, key);
    } else {
        return new Context(urlParameters.connector, urlParameters.collection, urlParameters.id);
    }
};

Context.fromCloudflowConnection = function(pConnectionOptions, pOptions) {
    var connector, collection, key;

    var cloudflowConnection;
    var dotIndex = pConnectionOptions.collection.indexOf(".");
    if (dotIndex === -1) {
        cloudflowConnection = pConnectionOptions.collection;
    } else {
        cloudflowConnection = pConnectionOptions.collection.substring(0, dotIndex);
    }
    switch(cloudflowConnection) {
        case "object":
            connector = "object";
            collection = "dummy";
            if (Array.isArray(pConnectionOptions.data)) {
                key = pConnectionOptions.data[0];
            } else {
                key = null;
            }
            break;
        case "file":
            connector = "file";
            collection = pConnectionOptions.dataFileURL;
            key = ["_id", "equal to", ""];
            break;
        case "nucleus":
        case "dataconnector":
            connector = cloudflowConnection;
            collection = pConnectionOptions.collection.substring(dotIndex + 1);
            key = "";
            break;
        case "whitepaperHTTPService":
            connector = "httpservice";
            collection = pConnectionOptions.httpserviceWhitepaperName;
            key = {
                inputname: pConnectionOptions.httpserviceInputName,
                variables: {}
            }
            break;
        case "whitepaperCRUD":
            connector = "startFromDBIO";
            var rest = pConnectionOptions.collection.substring(dotIndex + 1);
            var slashIndex = rest.indexOf("/");
            collection = rest.substring(0, slashIndex);
            key = {
                inputname: rest.substring(slashIndex + 1)
            }
            break;
        case "resource":
            connector = "resource";
            collection = pConnectionOptions.collection.substring(dotIndex + 1);
            key = "";
            break;
        default:
            connector = "nucleus";
            collection = pConnectionOptions.collection;
            key = "";
    }
    return new Context(connector, collection, key);
}

/**
 * @description function to save data to the local storage to be able to retrieve later on.
 * @funtion
 * @name Context.saveDatatoPass
 * @param {pObject} pObject
 * @return {String} The key, needed to retrieve the data, or null if there is no support or we could not save it
 */
Context.saveDataToPass = function(pObject) {
    if (Storage.isSupport() === false) {
        return null;
    }
    return Storage.write(pObject);
};

Context.prototype = {
    
    constructor: Context,
    
    getConnector: function() {
        return this.connector;
    },
    
    setConnector: function(pConnector) {
        if (pConnector === undefined || pConnector === null) {
            this.connector = null;
        } else if (typeof pConnector === "string") {
            this.connector = pConnector;
        } else {
            throw new Error("Context.setConnector parameter connector must be a string");
        }
    },
    
    getCollection: function() {
        return this.collection;
    },
    
    setCollection: function(pCollection) {
        this.collection = pCollection;
        if (typeof pCollection === "string" && pCollection.length > 0 && this.connector === null) {
            // if there is a specific collection and the connector is missing, set to default nucleus.
            this.connector = "nucleus";
        }
    },
    
    isInitiateAsEmpty: function(){
        return this.collection === undefined || this.collection === null; 
    },
    
    getKey: function() {
        return this.key;
    },
    
    setKey: function(pKey) {
        if (this.getConnector() === "object") {
            if (pKey === undefined || pKey === null) {
                // fallback for wrong inputs
                this.key = {};
            } else if ($.isPlainObject(pKey) === false) {
                throw new Error("Context.setKey the key must be an object if the collection is set to object");
            }
            this.key = Object.assign({}, pKey);
        } else if (this.getConnector() === "whitepaper" || this.getConnector() === "httpservice") {
            if ($.isPlainObject(pKey) === false) {
                throw new Error("Context.setKey the key must be an object if the collection is set to " + this.getConnector());
            }
            if (typeof pKey.inputname !== "string" || pKey.inputname.length <= 0) {
                throw new Error("Context.setKey the key must contain a key inputname");
            }
            if ($.isPlainObject(pKey.variables) === false) {
                throw new Error("Context.setKey the key must contain a key variables");
            }
            this.key = Object.assign({}, pKey);
        } else if (this.getConnector() === "startFromDBIO") {
            if ($.isPlainObject(pKey) === false) {
                throw new Error("Context.setKey the key must be an object if the collection is set to " + this.getConnector());
            }
            if (typeof pKey.inputname !== "string" || pKey.inputname.length <= 0) {
                throw new Error("Context.setKey the key must contain a key inputname");
            }
            this.key = Object.assign({}, pKey);
        } else if (Array.isArray(pKey)) {
            this.key = pKey.slice(); // copy
        } else {
            this.key = pKey;
        }
    },
    
    /**
     * @description will it take a lot of time to retrieve data from this specific context?
     * @function
     * @name context.canTakeTime
     * @return {Boolean} true if the context will take a lot of time to retrieve the data, false otherwise
     */
    canTakeTime: function(){
       return this.connector === "whitepaper" || this.connector === "startFromDBIO";  
    },
    
    /**
     * @description Load the data that corresponds to the context.
     * @function
     * @name Context#loadData
     * @return {Object} This return the requested data in form of a js plain object or a jQuery Deffered object
     */
    loadData: function(pOptions) {
        if (this.isInitiateAsEmpty()) {
            this.data = {};
            return $.Deferred().resolve(this.data);
        }
        if (this.connector === "object" && $.isPlainObject(this.key)) {
            this.data = Object.assign({}, this.key);
            return $.Deferred().resolve(this.data);
        }
        if (this.connector === "whitepaper") {
            var that = this;
            var connection = new WhitepaperConnection(this.collection, this.key.inputname, this.key.variables);
            if (pOptions !== undefined && typeof pOptions.startPollingTime === "number") {
                connection.setStartPollingTime(pOptions.startPollingTime);
            }
            this.currentWhitepaperConnections.push(connection);
            return connection.start().progress(function(pProgress){
                // check if there are actions requests
                if (pProgress !== undefined && typeof pProgress.action === "string") {
                    that._executeActionFromWhitepaper(pProgress, pOptions);
                    if (pOptions !== undefined && pOptions.suppressRedirect === true && pProgress.defer !== undefined) {
                        // end the defer because we want not redirect
                        pProgress.defer.resolve({});
                    }
                }
            }).done(function(pResult){
                that.data = pResult;
                if (that.mayUseCache() === true && connection.getWorkableID() !== null) {
                    that.setCache({workable_id: connection.getWorkableID()});
                }
            }).fail(function(){
                that.data = null;
            }).always(function(){
                var debugData = { 
                    debug_type: "workflow",
                    whitepaperName: that.collection,
                    whitepaperInputName: that.key.inputname
                };
                if (connection.getWorkableID() !== null) {
                    debugData.workableID = connection.getWorkableID();
                    debugData.jacketID = connection.getJacketID();
                }
                that.setDebugData(debugData);
            });
        }
        if (this.connector === "httpservice") {
            var that = this;
            var connection = new WhitepaperConnection(this.collection, this.key.inputname, this.key.variables);
            return connection.httpService()
            .then(function(pResult){
                if (pResult !== undefined && typeof pResult.error === "string" && typeof pResult.error_code === "string" &&
                    Array.isArray(pResult.messages)) {
                        that.data = null;
                        console.error(pResult);
                        return $.Deferred().reject(pResult);
                }
                that.data = pResult;
                return that.data;
            }, function(pError){
                that.data = null;
                return pError;
            })
            .always(function(){
                var debugData = { 
                    debug_type: "workflow",
                    whitepaperName: that.collection, 
                    whitepaperInputName: that.key.inputname
                };
                if (connection.getWorkableID() !== null) {
                    debugData.workableID = connection.getWorkableID();
                    debugData.jacketID = connection.getJacketID();
                }
                that.setDebugData(debugData);
            });
        }
        if (this.connector === "startFromDBIO") {
            var that = this;
            var connection;
            if ((typeof this.key.variables === "string" && this.key.variables.length > 0) || typeof this.key.variables === "number") {
                connection = new WhitepaperConnection(this.collection, this.key.inputname, [this.key.variables], {
                    connectionType: "database_proxy",
                    connectionFunctionName: "get",
                    connectionCallOptions: {}
                });
            } else if (Array.isArray(this.key.variables) && this.key.variables.length > 0) {
                connection = new WhitepaperConnection(this.collection, this.key.inputname, [this.key.variables, [], []], {
                    connectionType: "database_proxy",
                    connectionFunctionName: "list",
                    connectionCallOptions: {first: 0, maximum: 1}
                });
            } else {
                that.data = null;
                throw new Error("invalid key for Start From DBIO");
            }
            if (pOptions !== undefined && typeof pOptions.startPollingTime === "number") {
                connection.setStartPollingTime(pOptions.startPollingTime);
            }
            this.currentWhitepaperConnections.push(connection);
            return connection.start().then(function(pResult){
                if (pResult !== undefined && pResult.document !== undefined) {
                    that.data = pResult.document; // get 
                } else if (pResult !== undefined && Array.isArray(pResult.documents)) { 
                    if (pResult.documents.length > 0) {
                        that.data = pResult.documents[0]; // list
                    } else { // empty documents
                        that.data = null;
                        return $.Deferred().reject({"error_code": "empty_results_startfromdbio", "error": "No items found in Start from DBIO"});
                    }
                } else {
                    that.data = null;
                    return $.Deferred().reject({"error_code": "wrong_interface_startfromdbio", "error": "wrong returning interface of Start from DBIO"});
                }
                // in case of good results
                if (that.mayUseCache() === true && connection.getWorkableID() !== null) {
                    that.setCache({workable_id: connection.getWorkableID()});
                }
                return that.data;
            }, function(pError){
                that.data = null;
                return $.Deferred().reject(pError);
            })
            .always(function(){
                var debugData = { 
                    debug_type: "workflow",
                    whitepaperName: that.collection,
                    whitepaperInputName: that.key.inputname 
                };
                if (connection.getWorkableID() !== null) {
                    debugData.workableID = connection.getWorkableID();
                    debugData.jacketID = connection.getJacketID();
                }
                that.setDebugData(debugData);
            });
        }
        if (this.connector === "file") {
            var that = this;
            var relativeBasePath = "";
            if (pOptions !== undefined && typeof pOptions.relativeBasePath === "string") {
                relativeBasePath = pOptions.relativeBasePath;
            }
            var connection = new FileConnection(this.collection, relativeBasePath, "json");
            this.fileConnections.push(connection);
            return connection.load().then(function(){
                if (Array.isArray(that.key) && that.key.length > 0) {
                    return $.Deferred(function(pDefer){
                        connection.list_with_options(that.key, [], [], {first: 0, maximum: 1}, function(pResults){
                            if (pResults !== undefined && Array.isArray(pResults.results) && pResults.results.length > 0) {
                                that.data = pResults.results[0];
                                pDefer.resolve(that.data);
                            } else {
                                that.data = null;
                                pDefer.reject("no row found");
                            }
                        }, pDefer.reject);
                    });
                } else {
                    that.data = connection.getDataObject();
                    return that.data;
                }
            }, function() {
                that.setError(connection.getError());
                that.data = null;
            });
        }
        if (typeof this.collection === "string" && this.collection.length > 0) {
            var that = this;
            return _loadData(this.connector + "." + this.collection, this.key).done(function(pResult){
                that.data = pResult;
            }).fail(function(){
                that.data = null;
            });
        } else {
            throw new Error("Context.loadData: invalid collection and key for context");
        }
    },
    
    loadDataFromCache: function(pOptions) {
        if (this.mayUseCache() === true && this.hasCache() === true && this.connector === "whitepaper" && typeof this.cache.workable_id === "string" && this.cache.workable_id.length > 0) {
            var that = this;
            var connection = new WhitepaperConnection(this.collection, this.key.inputname, this.key.variables);
            return connection.catchup({workable_id: this.cache.workable_id}).progress(function(pProgress){
                // check if there are actions requests
                if (pProgress !== undefined && typeof pProgress.action === "string") {
                    that._executeActionFromWhitepaper(pProgress, pOptions);
                    if (pOptions !== undefined && pOptions.suppressRedirect === true && pProgress.defer !== undefined) {
                        // end the defer because we want not redirect
                        pProgress.defer.resolve({});
                    }
                }
            }).then(function(pResult){
                that.data = pResult;
                return that.data;
            }, function(pError) {
                if (pError !== undefined && pError.error_code === "Workable does not exist") {
                    // the workable or jacket does not exist anymore, 
                    // preventing by removing cache
                    that.clearCache();
                    console.warn("workable not found, cache cleared");
                } else {
                    console.error(pError);
                }
                // if fails try to redo ...
                return that.loadData(pOptions);
            });
        }
        // no cache found or possible, redo complete ...
        return this.loadData(pOptions);
    },
    
    /**
     * @description Sometimes the backend is asking for a specific action
     * @function
     * @private
     * @name Context#_executeActionFromWhitepaper
     * @return {undefined}
     */
    _executeActionFromWhitepaper: function(pAction, pCallOptions) {
        if (pAction !== undefined) {
            // action redirection
            if (pAction.action === "redirect") {
                // if not suppressed and url is not empty
                if ((pCallOptions === undefined  || pCallOptions.suppressRedirect !== true) && typeof pAction.url === "string" && pAction.url.length > 0) {
                    if (pAction.url.indexOf("cloudflow://") === 0) { // translate cloudflow paths
                        this._getWindowLocation().replace("/portal.cgi/" + pAction.url.slice(12)); 
                    } else {
                        this._getWindowLocation().replace(pAction.url);
                    }
                }
            }
        }
    },
    
    _getWindowLocation: function(){
        return window.location; // put in different function to be able to overwrite this function for tests
    },

    /**
     * @description Load the data and returns the context object itself
     * @function
     * @private
     * @name Context#loadDataAndReturnsMe
     * @return {Deferred}
     */
    loadDataAndReturnsMe: function(pUseCache) {
        var that = this;
        var defData;
        if (pUseCache === true) {
            defData = $.when(this.loadDataFromCache());
        } else {
            defData = $.when(this.loadData());
        }
        return defData.then(function(){
            return that;
        }, function(pError) {
            that.setError(pError);
            return $.Deferred().reject(pError);
        });
    },
    
    /**
     * @description Abort all current calls that loads the data
     * @function
     * @name Context#stopLoadingData
     * @returns {undefined}
     */
    stopLoadingData: function() {
        if (Array.isArray(this.currentWhitepaperConnections) === true) {
            for (var i = 0; i < this.currentWhitepaperConnections.length; i++) {
                if (this.currentWhitepaperConnections[i].hasLongLive() === true) {
                    continue;
                }
                this.currentWhitepaperConnections[i].stop();
            }
        }
        if (Array.isArray(this.fileConnections) === true) {
            for (var i = 0; i < this.fileConnections.length; i++) {
                this.fileConnections[i].stop();
            }
        }
    },
    
    /**
     * @description Did the context has some data?
     * @function
     * @name Context#hasData
     * @return {Boolean} true if the data has been loaded correctly of false if the data is not yet loaded or of the call to get has failed
     */
    hasData: function() {
        return this.data !== null;
    },
    
    /**
     * @description Get all the data that corresponds to the context. 
     * @function
     * @name Context#getData
     * @return {Object} An object containing all the requested information. This can be null if data is not yet loaded or an error occured during the request of the data.
     */
    getData: function(pPath) {
        if (pPath === undefined || this.data === null) {
            return this.data;
        } else if (typeof pPath === "string" && pPath.length > 0) {
            return CloudflowObject.getParameter(this.data, pPath);
        } else {
            throw new Error("Context.getData invalid interface of parameter");
        }
    },
    
    setData: function(pKey, pValue) {
        if (typeof pKey !== "string") {
            throw new Error("Context.setData parameter pKey must be a string");
        }
        if (this.hasData() === false) {
            this.data = {};
        }
        if (pKey === "") {
            Object.assign(this.data, pValue);
        } else {
            this.data = CloudflowObject.setParameter(this.data, pKey, pValue);
        }
    },
    
    /**
     * @description Add variable to a url to define a context, so the rendered page can retrieve thiose parameters
     * @function
     * @name Context#addToUrl
     * @param {String} pUrl the url to add and change
     * @param {String} pID (optional) the id to use 
     * @return {String} the url with variable to define a context
     */
    addToUrl: function(pUrl, pID) {
        if (typeof pUrl !== "string") {
            throw new Error("Context.addToUrl parameter url must be a string");
        }
        if (this.isInitiateAsEmpty()) {
            return pUrl;
        }
        var id;
        if (pID === undefined) {
            var key = this.getKey();
            if ($.isPlainObject(key)) {
                id = "&id=" + encodeURIComponent(JSON.stringify(key)); 
            } else if (Array.isArray(key) && key.length > 0) {
                // todo
                id = "";
                for (var i=0; i<key.length; i++) {
                    id += "&id[" + i + "]=" + encodeURIComponent(key[i]);
                }
            } else {
                id = "&id=" + encodeURIComponent(key);
            }
        } else if (Array.isArray(pID) && pID.length > 0) {
            id = "";
            for (var i=0; i<pID.length; i++) {
                id += "&id["+ i + "]=" + pID[i];
            }
        } else {
            id = "&id=" + pID;
        }
        var addedURLParameters = "connector=" + encodeURIComponent(this.getConnector()) + "&collection=" + encodeURIComponent(this.getCollection()) + id;
        var startParametersIndex = pUrl.indexOf("?");
        var hashtagIndex = pUrl.indexOf("#");
        if (startParametersIndex >= 0) {
            if (hashtagIndex > 0 && hashtagIndex < startParametersIndex) {
                // there is a hashTag # before the ?
                throw new Error("conflict: parameter url may not contain a '#' before the '?'.");
            }
            // there are alread existing parameters, try to add 
            var endPart = pUrl.slice(startParametersIndex + 1);
            if (endPart.indexOf("id=") >= 0 || endPart.indexOf("collection=") >= 0 || endPart.indexOf("connector=") >= 0) {
                throw new Error("conflict: parameter url already contains a 'id' or a 'collection' as parameter.");
            }
            if (endPart === "") {
                // "?" is last character
                pUrl = pUrl + addedURLParameters;
            } else if (hashtagIndex > startParametersIndex) {
                // a hashtag after the parameters chain
                pUrl = pUrl.substring(0, hashtagIndex) + "&" + addedURLParameters + pUrl.slice(hashtagIndex); 
            } else {
                // no hashtag found
                pUrl = pUrl + "&" + addedURLParameters; // add parameters to the end of the parameters chain
            }
        } else if (hashtagIndex > 0) {
            // there is a hashtag but no parameters, add hashtag and componay at the end
            pUrl = pUrl.substring(0, hashtagIndex) + "?" + addedURLParameters + pUrl.slice(hashtagIndex); 
        } else {
            // there are no existing parameters, 
            pUrl = pUrl + "?" + addedURLParameters;
        }
        return pUrl;
    },
    
    makeContextIdentifier: function() { 
        if ($.isPlainObject(this.getKey()) || Array.isArray(this.getKey())) {
            return this.getConnector() + ":" + this.getCollection() + ":" + JSON.stringify(this.getKey());
        } else {
            return this.getConnector() + ":" + this.getCollection() + ":" + this.getKey();
        }
    },
    
    setError: function(pError) {
        this.error = pError;
    },
    
    getError: function() {
        return this.error;
    },
    
    setUseCache: function(pUseCache) {
        // only check on true or something else
        this.useCache = pUseCache;
    },
    
    mayUseCache: function(){
        return this.useCache === true;
    },
    
    hasCache: function(){
        return this.cache !== undefined;
    },
    
    setCache: function(pCache) {
        if ($.isPlainObject(pCache)) {
            this.cache = pCache;
        } else {
            this.cache = undefined;
        }
    },
    
    getCache: function() {
        return this.cache;
    },
    
    clearCache: function() {
        this.cache = undefined;
    },

    setDebugData: function(pDebug) {
        if ($.isPlainObject(pDebug)) {
            this.debug = Object.assign({}, pDebug);
        } else {
            this.debug = undefined;
        }
    },

    getDebugData: function(){
        return this.debug;
    },

    isEqual: function(pContext) {
        // make to compare a valid context element
        var contextToCompare;
        if ($.isPlainObject(pContext)) {
            contextToCompare = Context.fromJSON(pContext);
        } else if (pContext instanceof Context) {
            contextToCompare = pContext;
        } else {
            return false;
        }
        // both can differ from collection but is isInitateAsEmpty is true on both
        // return there are the same
        if (this.isInitiateAsEmpty() === true && this.isInitiateAsEmpty() === contextToCompare.isInitiateAsEmpty()) {
            return true;
        }
        // check first "Connector"
        if (this.getConnector() !== contextToCompare.getConnector()) {
            return false;
        }
        // "objects" van have different collections and are only defined by key
        if (this.getConnector() === "object") {
            return _.isEqual(this.getKey(), contextToCompare.getKey());
        } else if (this.getCollection() !== contextToCompare.getCollection()) {
            // check collections
            return false;
        } 
        // check keys
        return _.isEqual(this.getKey(), contextToCompare.getKey());
    },

    toJSON: function() {
        var key = this.getKey();
        if (key === undefined) {
            key = null; // prevent undefined in JSON
        } else if ($.isPlainObject(key)) {
            key = Object.assign({}, key); // make copy 
        } else if (Array.isArray(key)) {
            key = key.slice(); // make copy 
        }
        var output = {
            connector: this.connector,
            collection: (this.getCollection() === undefined) ? null : this.getCollection(),
            key: key
        };
        if (this.hasCache() === true) {
            output.cache = Object.assign({}, this.cache);
        }
        if (this.mayUseCache() === true) {
            output.useCache = true;
        }
        return output;
    },
    
    /**
     * @description Clone the context object
     * @function
     * @name context.clone
     * @param {Boolean} pCloneData Must we also copy the internal data?
     */
    clone: function(pCloneData) {
        var newContext = Context.fromJSON(this.toJSON());
        if (pCloneData === true && this.hasData()) {
            var data = this.getData();
            if ($.isPlainObject(data)) {
                newContext.data = Object.assign({}, data); 
            }
        }
        return newContext;
    }
};

module.exports = Context;