var WhitepaperCRUD = require("./WhitepaperCRUD.js");
var CloudflowObject = require("../../CloudflowUtil/js/CloudflowObject.js");
/**
 * @description A dictionary containing all the fix querys for each collection
 * @type {Object.<Array>}
 * @static
 * @private
 */
var prefixQuerys = Object.freeze({
    "nucleus.job": ["template", "does not exist"],
    "nucleus.project": ["template", "does not exist"],
    "nucleus.job_recursive": ["template", "does not exist"],
    "nucleus.users": ["(", "username", "not equal to", "guest", "and", "username", "not equal to", "event", ")"],
    "nucleus.scope": ["temporary", "does not exist"],
    "nucleus.bucket": ["temporary", "does not exist"],
    "nucleus.agent": ["name", "not equal to", "*"]
});

/**
 * @description A RegExp to filter out all the arrays, otherwise mongo will return nothing
 * @type {RegExp}
 * @static
 * @private
 */
var numberFieldPathRegExp = new RegExp("[.]([0-9]+[.]){1,}", "g");

function _getPredefinedFilter(pCollection) {
    if (prefixQuerys[pCollection] !== undefined) {
        return prefixQuerys[pCollection].slice();
    }
    return [];
};

function _normaliseCollectionName(pCollectionName) {
    if (typeof pCollectionName !== "string" || pCollectionName.length <= 0) {
        return pCollectionName; // check or error handling should be done by caller
    }
    // check if there is already a prefix
    if (pCollectionName.indexOf("nucleus.") === 0 || pCollectionName.indexOf("dataconnector.") === 0) {
        // name is already normalised
        return pCollectionName;
    } else {
        // there is no prefix, so add default one
        return "nucleus." + pCollectionName;
    }
}

function _getDetailsDataConnector(pCollectionName) {
    if (pCollectionName.indexOf("dataconnector.") === 0) {
        var infoPart = pCollectionName.substring(14); // remove prefix
        var separatorIndex = infoPart.lastIndexOf("/"); // first part is data_connector, second part is table, try to retrieve each part
        if (typeof separatorIndex === "number" && separatorIndex > 0) {
            return {
                name: infoPart.substring(0, separatorIndex),
                table: infoPart.substring(separatorIndex + 1)
            };
        }
        return null;
    }
    return null;
}

function _mokeupDataConnectorQuery(pQuery) {
    /*if (Array.isArray(pQuery) && pQuery.length > 0) {
        var outputQuery = [];
        for (var i=0; i< pQuery.length; i++) {
            if (pQuery[i] === "contains text like") {
                outputQuery.push("contains");
                console.error("the query to dataconnector has been changed becasue it does no support the "contains text like" query");
            } else {
                outputQuery.push(pQuery[i]);
            }
        }
        return outputQuery;
    }*/
    return pQuery;
}


var DatabaseConnection = {};

DatabaseConnection.list_with_options = function(pCollection, pQuery, pOrderby, pFields, pOptions, pGoodCallBack, pBadCallBack) {
    // make deep copy, to prevent changes
    if (Array.isArray(pQuery)) {
        pQuery = pQuery.slice();
    }
    if ($.isPlainObject(pCollection)) {
        var connection = WhitepaperCRUD.fromJSON(pCollection);
        connection.list_with_options(pQuery, pOrderby, pFields, pOptions, pGoodCallBack, pBadCallBack);
        return;
    }
    var collectionName = _normaliseCollectionName(pCollection);
    var endQuery = _getPredefinedFilter(collectionName);
    if (Array.isArray(pQuery) && pQuery.length > 0) {
        if (endQuery.length > 0) {
            pQuery.unshift("(");
            pQuery.push(")");
            pQuery.push("and");
        }
        endQuery = pQuery.concat(endQuery);
    }
    if (collectionName.indexOf("dataconnector.") === 0) {
        var dataconnectionInfo = _getDetailsDataConnector(collectionName);
        if (dataconnectionInfo !== null) {
            api_async.dataconnector.list(dataconnectionInfo.name, dataconnectionInfo.table, _mokeupDataConnectorQuery(endQuery), pOrderby, pFields, pOptions, pGoodCallBack, pBadCallBack);
        } else {
            pBadCallBack({ "error_code": "wrong_collectionname", "error": "wrong collectionname: " + collectionName });
        }
        return;
    }
    if (Array.isArray(pFields) && pFields.length > 0) {
        for (var i=0; i<pFields.length; i++) {
            if (typeof pFields[i] === "string" && pFields[i].length > 0) {
                // remove all .0. from the fields, otherwise the list with options will return nothing
                pFields[i] = pFields[i].replace(numberFieldPathRegExp, ".");
            }
        }
    }
    if (collectionName.indexOf("nucleus.customobjects.") === 0) {
        api_async.custom_objects.list_with_options(collectionName.substring(22), endQuery, pOrderby, pFields, pOptions, pGoodCallBack, pBadCallBack);
        return;
    }
    switch (collectionName) {
        case "nucleus.job":
        case "nucleus.project":
            api_async.job.list_with_options(endQuery, pOrderby, pFields, pOptions, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.job_recursive":
            if (pOptions === undefined || pOptions === null) {
                pOptions = {};
            }
            pOptions.recursive = true;
            api_async.job.list_with_options(endQuery, pOrderby, pFields, pOptions, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.users":
            api_async.users.list_with_options(endQuery, pOrderby, pFields, pOptions, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.approval":
            api_async.approval.list_with_options(endQuery, pOrderby, pFields, pOptions, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.asset":
            api_async.asset.list_with_options(endQuery, pOrderby, pFields, pOptions, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.stats_active_users":
            api_async.stats.active_users.list_with_options(endQuery, pOrderby, pFields, pOptions, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.stats_database":
            api_async.stats.database.list_with_options(endQuery, pOrderby, pFields, pOptions, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.stats_workers":
            api_async.stats.workers.list_with_options(endQuery, pOrderby, pFields, pOptions, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.stats_work_servers":
            api_async.stats.work_servers.list_with_options(endQuery, pOrderby, pFields, pOptions, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.team":
            api_async.team.list_with_options(endQuery, pOrderby, pFields, pOptions, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.template":
            api_async.template.list_with_options(endQuery, pOrderby, pFields, pOptions, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.task_list":
            api_async.task_list.list_with_options(endQuery, pOrderby, pFields, pOptions, pGoodCallBack, pBadCallBack);
            break;
        default:
            api_async.database.document.list_with_options(collectionName, endQuery, pOrderby, pFields, pOptions, function(pResult) {
                var GoodKey = {};
                GoodKey.results = pResult.documents;
                pGoodCallBack(GoodKey)
            }, pBadCallBack);
    }
};

DatabaseConnection.list = function(pCollection, pQuery, pFields, pGoodCallBack, pBadCallBack) {
    // make deep copy, to prevent changes
    if (Array.isArray(pQuery)) {
        pQuery = pQuery.slice();
    }
    if ($.isPlainObject(pCollection)) {
        var connection = WhitepaperCRUD.fromJSON(pCollection);
        connection.list_with_options(pQuery, [], pFields, {}, pGoodCallBack, pBadCallBack);
        return;
    }
    var collectionName = _normaliseCollectionName(pCollection);
    var endQuery = _getPredefinedFilter(collectionName);
    if (Array.isArray(pQuery) && pQuery.length > 0) {
        if (endQuery.length > 0) {
            pQuery.unshift("(");
            pQuery.push(")");
            pQuery.push("and");
        }
        endQuery = pQuery.concat(endQuery);
    }
    if (collectionName.indexOf("dataconnector.") === 0) {
        var dataconnectionInfo = _getDetailsDataConnector(collectionName);
        if (dataconnectionInfo !== null) {
            api_async.dataconnector.list(dataconnectionInfo.name, dataconnectionInfo.table, _mokeupDataConnectorQuery(endQuery), [], pFields, {}, pGoodCallBack, pBadCallBack);
        } else {
            pBadCallBack({ "error_code": "wrong_collectionname", "error": "wrong collectionname: " + collectionName });
        }
        return;
    }
    if (Array.isArray(pFields) && pFields.length > 0) {
        for (var i=0; i<pFields.length; i++) {
            if (typeof pFields[i] === "string" && pFields[i].length > 0) {
                // remove all .0. from the fields, otherwise the list with options will return nothing
                pFields[i] = pFields[i].replace(numberFieldPathRegExp, ".");
            }
        }
    }
    if (collectionName.indexOf("nucleus.customobjects.") === 0) {
        api_async.custom_objects.list(collectionName.substring(22), endQuery, pFields, pGoodCallBack, pBadCallBack);
        return;
    }
    switch (collectionName) {
        case "nucleus.job":
        case "nucleus.project":
            api_async.job.list(endQuery, pFields, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.users":
            api_async.users.list(endQuery, pFields, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.approval":
            api_async.approval.list(endQuery, pFields, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.asset":
            api_async.asset.list(endQuery, pFields, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.scope":
            api_async.scope.list(endQuery, pFields, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.stats_active_users":
            api_async.stats.active_users.list(endQuery, pFields, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.stats_database":
            api_async.stats.database.list(endQuery, pFields, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.stats_workers":
            api_async.stats.workers.list(endQuery, pFields, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.stats_work_servers":
            api_async.stats.work_servers.list(endQuery, pFields, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.team":
            api_async.team.list(endQuery, pFields, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.template":
            api_async.template.list(endQuery, pFields, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.task_list":
            api_async.task_list.list(endQuery, pFields, pGoodCallBack, pBadCallBack);
            break;
        default:
            //fix result key
            api_async.database.document.list_with_options(collectionName, endQuery, [], pFields, {}, function(pResult) {
                var GoodKey = {};
                GoodKey.results = pResult.documents;
                pGoodCallBack(GoodKey)
            }, pBadCallBack);
    }
};

//Override documents key by results key
var GoodCallBack = {};
GoodCallBack.results = function(pGoodCallBack) {
    return { "results": pGoodCallBack.documents };
};

DatabaseConnection.get = function(pCollection, pID, pGoodCallBack, pBadCallBack) {
    if (typeof pID !== "string" || pID.length <= 0) {
        pBadCallBack({ "error_code": "wrong_parameters", "error": "DatabaseConnection.get: parameter pID must be a not empty string" });
        return;
    }
    if ($.isPlainObject(pCollection)) {
        var connection = WhitepaperCRUD.fromJSON(pCollection);
        connection.get(pID, pGoodCallBack, pBadCallBack);
        return;
    }
    var collectionName = _normaliseCollectionName(pCollection);
    if (collectionName.indexOf("dataconnector.") === 0) {
        var dataconnectionInfo = _getDetailsDataConnector(collectionName);
        if (dataconnectionInfo !== null) {
            api_async.dataconnector.get(dataconnectionInfo.name, dataconnectionInfo.table, pID, {}, pGoodCallBack, pBadCallBack);
        } else {
            pBadCallBack({ "error_code": "wrong_collectionname", "error": "wrong collectionname: " + collectionName });
        }
        return;
    } else if (collectionName.indexOf("nucleus.customobjects.") === 0) {
        api_async.custom_objects.get(collectionName.substring(22), pID, pGoodCallBack, pBadCallBack);
        return;
    }
    switch (collectionName) {
        case "nucleus.job":
        case "nucleus.project":
        case "nucleus.job_recursive":
            if (pID.indexOf("-") === -1) {
                // mongo id
                api_async.job.get_recursive(pID, {}, pGoodCallBack, pBadCallBack);
            } else {
                // project_id
                api_async.job.get_by_project_id(pID, {}, pGoodCallBack, pBadCallBack);
            }
            break;
        case "nucleus.users":
            api_async.users.get(pID, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.approval":
            api_async.approval.get(pID, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.asset":
            api_async.asset.get(pID, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.jobrecursive": // old case
            // mongoID
            api_async.job.get_recursive(pID, {}, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.portal_get_stats":
            api_async.portal.get_stats(pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.portal_get_db_status":
            api_async.portal.get_db_status({}, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.scope":
            api_async.scope.get(pID, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.stats_active_users":
            api_async.stats.active_users.list(["_id", "equal to", pID], [], pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.stats_database":
            api_async.stats.database.list(["_id", "equal to", pID], [], pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.stats_workers":
            api_async.stats.workers.list(["_id", "equal to", pID], [], pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.stats_work_servers":
            api_async.stats.work_servers.list(["_id", "equal to", pID], [], pGoodCallBack, pBadCallBack);
            break;
        case "nucleus._get_license":
            api_async.license.get_license({}, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.template":
            api_async.template.get(pID, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.task_list":
            if (pID.indexOf("-") === -1) {
                // mongo id
                api_async.task_list.get(pID, pGoodCallBack, pBadCallBack);
            } else {
                // task_list_id
                api_async.task_list.get_by_task_list_id(pID, {}, pGoodCallBack, pBadCallBack);
            }
            break;
        default:
            api_async.database.document.get(collectionName, pID, pGoodCallBack, pBadCallBack);
    }
};

DatabaseConnection.set_keys = function(pCollection, pID, pKeyData, pGoodCallBack, pBadCallBack) {
    if ($.isPlainObject(pCollection)) {
        var connection = WhitepaperCRUD.fromJSON(pCollection);
        connection.set_keys(pID, pKeyData, pGoodCallBack, pBadCallBack);
        return;
    }
    var collectionName = _normaliseCollectionName(pCollection);
    if (collectionName.indexOf("nucleus.customobjects.") === 0) {
        api_async.custom_objects.set_keys(collectionName.substring(22), pID, pKeyData, pGoodCallBack, pBadCallBack);
        return;
    } else if (collectionName.indexOf("dataconnector.") === 0) {
        DatabaseConnection.set_keys_by_query(pCollection, ["ID", "equal to", pID], pKeyData, {}, pGoodCallBack, pBadCallBack);
        return;
    }

    switch (pCollection) {
        case "nucleus.job":
        case "nucleus.project":
        case "nucleus.job_recursive":
            api_async.job.set_keys(pID, pKeyData, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.users":
            api_async.users.set_keys(pID, pKeyData, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.asset":
            // administartor ...
            api_async.asset.set_keys(pID, pKeyData, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.scope":
            // api_async.scope.set_keys(pID, pKeyData, pGoodCallBack, pBadCallBack);
            api_async.database.document.set_keys(pCollection, pID, pKeyData, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.template":
            // api_async.template.set_keys(pQuery, pKeyData, pGoodCallBack, pBadCallBack);
            api_async.database.document.set_keys(pCollection, pID, pKeyData, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.task_list":
            api_async.task_list.set_keys(pID, pKeyData, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.portal_get_stats":
        case "nucleus.portal_get_db_status":
        case "nucleus.stats_active_users":
        case "nucleus.stats_database":
        case "nucleus.stats_workers":
        case "nucleus.stats_work_servers":
            pBadCallBack({ "error_code": "readonly_collection", "error": "DatabaseConnection.set_keysy: collection " + pCollection + " is readonly"});
            break
        default:
            api_async.database.document.set_keys(pCollection, pID, pKeyData, pGoodCallBack, pBadCallBack);
    }
};

DatabaseConnection.set_keys_by_query = function(pCollection, pQuery, pKeyData, pOptions, pGoodCallBack, pBadCallBack) {
    if ($.isPlainObject(pCollection)) {
        var connection = WhitepaperCRUD.fromJSON(pCollection);
        connection.set_keys_by_query(pQuery, pKeyData, pOptions, pGoodCallBack, pBadCallBack);
        return;
    }
    var collectionName = _normaliseCollectionName(pCollection);
    if (collectionName.indexOf("nucleus.customobjects.") === 0) {
        api_async.custom_objects.set_keys_by_query(collectionName.substring(22), pQuery, pKeyData, pOptions, pGoodCallBack, pBadCallBack);
        return;
    } else if (collectionName.indexOf("dataconnector.") === 0) {
        var dataconnectionInfo = _getDetailsDataConnector(collectionName);
        if (dataconnectionInfo !== null) {        
            DatabaseConnection.list_with_options(pCollection, pQuery, [], [], pOptions, function(pResult){
                if (pResult !== undefined && Array.isArray(pResult.results) && pResult.results.length > 0) {
                    var dataDefs = [];
                    for (var i=0; i<pResult.results.length; i++) {
                        for (var key in pKeyData) {
                            pResult.results[i] = CloudflowObject.setParameter(pResult.results[i], key, pKeyData[key]);
                        }
                        (function(index){
                            dataDefs.push($.Deferred(function(pDefer){
                                api_async.dataconnector.update(dataconnectionInfo.name, dataconnectionInfo.table, pResult.results[index], pOptions, pDefer.resolve, pDefer.resolve);
                            }));
                        })(i);
                        $.when.apply($, dataDefs)
                        .always(function(){
                            pGoodCallBack({});
                        })
                    }
                } else {
                    pGoodCallBack({});
                }
            }, pBadCallBack);
        } else {
            pBadCallBack({ "error_code": "wrong_collectionname", "error": "wrong collectionname: " + collectionName });
        }
        return;
    }

    switch (pCollection) {
        case "nucleus.job":
        case "nucleus.project":
        case "nucleus.job_recursive":
            api_async.job.set_keys_by_query(pQuery, pKeyData, pOptions, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.users":
            //api_async.users.set_keys_by_query(pQuery, pOptions, pGoodCallBack, pBadCallBack);
            //pBadCallBack({ "error_code": "missing_api", "error": "DatabaseConnection.set_keys_by_query: collection " + pCollection + " api is missing"});
            api_async.database.document.set_keys_by_query(pCollection, pQuery, pKeyData, pOptions, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.asset":
            // administartor ...
            api_async.asset.set_keys_by_query(pQuery, pKeyData, pOptions, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.scope":
            // api_async.scope.set_keys_by_query(pQuery, pOptions, pGoodCallBack, pBadCallBack);
            //pBadCallBack({ "error_code": "missing_api", "error": "DatabaseConnection.set_keys_by_query: collection " + pCollection + " api is missing"});
            api_async.database.document.set_keys_by_query(pCollection, pQuery, pKeyData, pOptions, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.template":
            // api_async.template.set_keys_by_query(pQuery, pOptions, pGoodCallBack, pBadCallBack);
            //pBadCallBack({ "error_code": "missing_api", "error": "DatabaseConnection.set_keys_by_query: collection " + pCollection + " api is missing"});
            api_async.database.document.set_keys_by_query(pCollection, pQuery, pKeyData, pOptions, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.task_list":
            api_async.task_list.set_keys_by_query(pQuery, pKeyData, pOptions, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.portal_get_stats":
        case "nucleus.portal_get_db_status":
        case "nucleus.stats_active_users":
        case "nucleus.stats_database":
        case "nucleus.stats_workers":
        case "nucleus.stats_work_servers":
            pBadCallBack({ "error_code": "readonly_collection", "error": "DatabaseConnection.set_keys_by_query: collection " + pCollection + " is readonly"});
            break
        default:
            api_async.database.document.set_keys_by_query(pCollection, pQuery, pKeyData, pOptions, pGoodCallBack, pBadCallBack);
    }
};

DatabaseConnection.delete = function(pCollection, pID, pOptions, pGoodCallBack, pBadCallBack) {
    if (typeof pID !== "string" || pID.length <= 0) {
        pBadCallBack({ "error_code": "wrong_parameters", "error": "DatabaseConnection.delete: parameter pID must be a not empty string" });
        return;
    }
    if ($.isPlainObject(pCollection)) {
        var connection = WhitepaperCRUD.fromJSON(pCollection);
        connection.delete(pID, pGoodCallBack, pBadCallBack);
        return;
    }
    var collectionName = _normaliseCollectionName(pCollection);
    if (collectionName.indexOf("nucleus.customobjects.") === 0) {
        api_async.custom_objects.delete(collectionName.substring(22), pID, pGoodCallBack, pBadCallBack);
        return;
    } else if (collectionName.indexOf("dataconnector.") === 0) {
        pBadCallBack({ "error_code": "no_support", "error": "DatabaseConnection.delete: no support for dataconnector" });
        return;
    }

    switch (pCollection) {
        case "nucleus.job":
        case "nucleus.project":
        case "nucleus.job_recursive":
            api_async.job.delete(pID, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.users":
            api_async.users.delete(pID, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.asset":
            api_async.asset.delete(pID, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.template":
            api_async.template.delete(pID, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.scope":
            api_async.scope.delete(pID, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.task_list":
            api_async.task_list.delete(pID, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.portal_get_stats":
        case "nucleus.portal_get_db_status":
        case "nucleus.stats_active_users":
        case "nucleus.stats_database":
        case "nucleus.stats_workers":
        case "nucleus.stats_work_servers":
            pBadCallBack({ "error_code": "readonly_collection", "error": "DatabaseConnection.delete: collection " + pCollection + " is readonly"});
            break
        default:
            api_async.database.document.delete(pCollection, pID, pGoodCallBack, pBadCallBack);
    }
};

DatabaseConnection.delete_by_query = function(pCollection, pQuery, pOptions, pGoodCallBack, pBadCallBack) {
    if ($.isPlainObject(pCollection)) {
        var connection = WhitepaperCRUD.fromJSON(pCollection);
        connection.delete_by_query(pQuery, pGoodCallBack, pBadCallBack);
        return;
    }
    var collectionName = _normaliseCollectionName(pCollection);
    if (collectionName.indexOf("nucleus.customobjects.") === 0) {
        api_async.custom_objects.delete_by_query(collectionName.substring(22), pQuery, pOptions, pGoodCallBack, pBadCallBack);
        return;
    } else if (collectionName.indexOf("dataconnector.") === 0) {
        pBadCallBack({ "error_code": "no_support", "error": "DatabaseConnection.delete_by_query: no support for dataconnector" });
        return;
    }

    switch (pCollection) {
        case "nucleus.job":
        case "nucleus.project":
        case "nucleus.job_recursive":
            api_async.job.delete_by_query(pQuery, pOptions, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.users":
            api_async.users.delete_by_query(pQuery, pOptions, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.asset":
            api_async.asset.delete_by_query(pQuery, pOptions, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.scope":
            api_async.scope.delete_by_query(pQuery, pOptions, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.template":
            api_async.template.delete_by_query(pQuery, pOptions, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.task_list":
            api_async.task_list.delete_by_query(pQuery, pOptions, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.portal_get_stats":
        case "nucleus.portal_get_db_status":
        case "nucleus.stats_active_users":
        case "nucleus.stats_database":
        case "nucleus.stats_workers":
        case "nucleus.stats_work_servers":
            pBadCallBack({ "error_code": "readonly_collection", "error": "DatabaseConnection.delete_by_query: collection " + pCollection + " is readonly"});
            break
        default:
            api_async.database.document.delete_by_query(pCollection, pQuery, pOptions, pGoodCallBack, pBadCallBack);
    }
};

DatabaseConnection.count = function(pCollection, pQuery, pGoodCallBack, pBadCallBack) {
    // make deep copy, to prevent changes
    if (Array.isArray(pQuery)) {
        pQuery = pQuery.slice();
    }
    if ($.isPlainObject(pCollection)) {
        var connection = WhitepaperCRUD.fromJSON(pCollection);
        connection.count(pQuery, pGoodCallBack, pBadCallBack);
        return;
    }
    var collectionName = _normaliseCollectionName(pCollection);
    var endQuery = _getPredefinedFilter(collectionName);
    if (Array.isArray(pQuery) && pQuery.length > 0) {
        if (endQuery.length > 0) {
            pQuery.unshift("(");
            pQuery.push(")");
            pQuery.push("and");
        }
        endQuery = pQuery.concat(endQuery);
    }
    if (collectionName.indexOf("dataconnector.") === 0) {
        var dataconnectionInfo = _getDetailsDataConnector(collectionName);
        if (dataconnectionInfo !== null) {
            api_async.dataconnector.list(dataconnectionInfo.name, dataconnectionInfo.table, _mokeupDataConnectorQuery(endQuery), [], [], {}, pGoodCallBack, pBadCallBack);
        } else {
            pBadCallBack({ "error": "wrong collectionname: " + collectionName });
        }
        return;
    } else if (collectionName.indexOf("nucleus.customobjects.") === 0) {
        api_async.custom_objects.count(collectionName.substring(22), endQuery, pGoodCallBack, pBadCallBack);
        return;
    }
    switch (collectionName) {
        case "nucleus.job":
        case "nucleus.project":
            api_async.job.count(endQuery, {}, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.job_recursive":
            api_async.job.count(endQuery, { recursive: true }, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.users":
            api_async.users.list_with_options(endQuery, [], ["_id"], {}, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.approval":
            // .count only for admins
            api_async.approval.list_with_options(endQuery, [], ["_id"], {}, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.scope":
            api_async.scope.list_with_options(endQuery, [], ["_id"], {}, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.stats_active_users":
            api_async.stats.active_users.list_with_options(endQuery, [], ["_id"], {}, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.stats_workers":
            api_async.stats.workers.list_with_options(endQuery, [], ["_id"], {first:0, maximum: 1, count: true}, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.stats_work_servers":
            api_async.stats.work_servers.list_with_options(endQuery, [], ["_id"], {first:0, maximum: 1, count: true}, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.template":
            api_async.template.list_with_options(endQuery, [], ["_id"], {}, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.task_list":
            api_async.job.count(endQuery, {}, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.asset":
        case "nucleus.jacket":
        case "nucleus.logs":
            pBadCallBack({ "error_code": "no_support", "error": "for performans reasons we do not support count on " + collectionName });
            break;
        default:
            api_async.database.document.count(collectionName, endQuery, {}, pGoodCallBack, pBadCallBack);
    }
};

DatabaseConnection.create = function(pCollection, pObject, pGoodCallBack, pBadCallBack) {
    if ($.isPlainObject(pCollection)) {
        var connection = WhitepaperCRUD.fromJSON(pCollection);
        connection.create(pObject, pGoodCallBack, pBadCallBack);
        return;
    }
    var collectionName = _normaliseCollectionName(pCollection);
    if (collectionName.indexOf("dataconnector.") === 0) {
        var dataconnectionInfo = _getDetailsDataConnector(collectionName);
        if (dataconnectionInfo !== null) {
            pBadCallBack({ "error_code": "not_programmed_yet", "error": "create functionality does not exist for dataconnector"});
        } else {
            pBadCallBack({ "error": "wrong collectionname: " + collectionName });
        }
        return;
    } else if (collectionName.indexOf("nucleus.customobjects.") === 0) {
        api_async.custom_objects.create(collectionName.substring(22), pObject, pGoodCallBack, pBadCallBack);
        return;
    }
    switch (collectionName) {
        case "nucleus.job":
        case "nucleus.project":
        case "nucleus.job_recursive":
            api_async.job.create(pObject, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.users":
            api_async.users.create(pObject, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.approval":
            api_async.approval.create(pObject, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.scope":
            api_async.scope.create(pObject, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.template":
            api_async.template.create(pObject, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.task_list":
            api_async.task_list.create(pObject, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.logs":
        case "nucleus.asset":
        case "nucleus.jacket":
        case "nucleus.portal_get_stats":
        case "nucleus.portal_get_db_status":
        case "nucleus.stats_active_users":
        case "nucleus.stats_database":
        case "nucleus.stats_workers":
        case "nucleus.stats_work_servers":
            pBadCallBack({ "error_code": "readonly_collection", "error": "DatabaseConnection.create: collection " + pCollection + " is readonly"});
            break
        default:
            api_async.database.document.create(collectionName, pObject, pGoodCallBack, pBadCallBack);
    }
};

DatabaseConnection.update = function(pCollection, pObject, pGoodCallBack, pBadCallBack) {
    if ($.isPlainObject(pCollection)) {
        var connection = WhitepaperCRUD.fromJSON(pCollection);
        connection.update(pObject, pGoodCallBack, pBadCallBack);
        return;
    }
    var collectionName = _normaliseCollectionName(pCollection);
    if (collectionName.indexOf("dataconnector.") === 0) {
        var dataconnectionInfo = _getDetailsDataConnector(collectionName);
        if (dataconnectionInfo !== null) {
            api_async.dataconnector.update(dataconnectionInfo.name, dataconnectionInfo.table, pObject, {}, pGoodCallBack, pBadCallBack);
        } else {
            pBadCallBack({ "error": "wrong collectionname: " + collectionName });
        }
        return;
    } else if (collectionName.indexOf("nucleus.customobjects.") === 0) {
        api_async.custom_objects.update(collectionName.substring(22), pObject, pGoodCallBack, pBadCallBack);
        return;
    }
    switch (collectionName) {
        case "nucleus.job":
        case "nucleus.project":
        case "nucleus.job_recursive":
            api_async.job.update(pObject, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.users":
            api_async.users.update(pObject, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.approval":
            // only admins
            api_async.approval.update(pObject, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.scope":
            api_async.scope.update(pObject, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.template":
            api_async.template.update(pObject, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.task_list":
            api_async.task_list.update(pObject, pGoodCallBack, pBadCallBack);
            break;
        case "nucleus.logs":
        case "nucleus.asset":
        case "nucleus.jacket":
        case "nucleus.portal_get_stats":
        case "nucleus.portal_get_db_status":
        case "nucleus.stats_active_users":
        case "nucleus.stats_database":
        case "nucleus.stats_workers":
        case "nucleus.stats_work_servers":
            pBadCallBack({ "error_code": "readonly_collection", "error": "DatabaseConnection.update: collection " + pCollection + " is readonly"});
            break
        default:
            api_async.database.document.update(collectionName, pObject, pGoodCallBack, pBadCallBack);
    }
};

module.exports = DatabaseConnection;