function isReload() {
    if (window.performance !== undefined) {
        // first check new function
        if (typeof performance.getEntriesByType === "function") {
            var results = performance.getEntriesByType("navigation");
            if (Array.isArray(results) && results.length > 0 && typeof results[0].type === "string") {
                return results[0].type === "reload";
            }
        }
        // if not found check deprecated function
        if (performance.navigation !== undefined && typeof performance.navigation.type === "number") {
            return performance.navigation.type === 1;
        }
    }
    return false;
}
var isReloaded = isReload();

var TemporyBrowserObjectCache = {
    save: function(pIdentifier, pObject){
        try {
            // session can not exists or storage can be full
            // so ignore errors
            sessionStorage.setItem(pIdentifier, JSON.stringify({"object": pObject, "creation": +(new Date())}));
        } catch(e) {}
    },

    get: function(pIdentifier) {
        try {
            // sessionStorage can not exists or 
            // returnValue can not be JSON parsed correctly
            // in all cases ignore and go to retrieve by api call
            var stringified = sessionStorage.getItem(pIdentifier);
            if (typeof stringified === "string" && stringified.length > 0) {
                var memoryObject = JSON.parse(stringified);
                if (memoryObject.object !== undefined && memoryObject.creation !== undefined) {
                    var now = new Date();
                    if (now.setHours(now.getHours() - 12) < memoryObject.creation) { // if created more recent then history
                        return memoryObject.object;
                    }
                }
                // continue in case of wrong
                sessionStorage.removeItem(pIdentifier);
            }
        } catch(e) {console.error(e);}
        return null;
    },

    remove: function(pIdentifier) {
        if (window.sessionStorage !== undefined) {
            window.sessionStorage.removeItem(pIdentifier);
        }
    },

    isPageReload: function(){
        return isReloaded == true;
    }
};

module.exports = TemporyBrowserObjectCache;