/**
 * @constructor CloudflowObject
 * @description Some hulp functions to handle with objects. <br />
 * To use this object you need to require it, when browserify 
 * @static
 */
var CloudflowObject = function() {
    
};

/**
 * @description Get the value of in the object tree, given an path with dot notation
 * @function
 * @name CloudflowObject.getParameter
 * @param {Object} pObject the object tree where to search
 * @param {String} pField The path with dot notation
 * @return {object} The value situated ot that possition or undefined if not found
 */
CloudflowObject.getParameter = function(pObject, pField) {
    if (Array.isArray(pObject) && parseInt(pField) > -1) {
        // skip if parameters is valid array
    } else if (typeof pObject !== "object" || $.isEmptyObject(pObject)) {
        return undefined;
    }
    if (typeof pField !== "string" || pField.length <= 0) {
        return pObject;
    }
    var objectPath = pField.split(".");
    var last = pObject;
    for (var i = 0; i < objectPath.length; i++) {
        // control if subkey is string, if two points are placed the split retuns a empty string, remove those cases
        if (typeof objectPath[i] === "string" && objectPath[i].length > 0) {
            if (last[objectPath[i]] === undefined) {
                // prevent errors, just stop loop
                return undefined;
            } else {
                last = last[objectPath[i]];
            }
        }
        // in other cases do nothing, ingore the error case 
    }
    return last;
};

/**
 * @description Change a parameter in the object tree, given a path "pField" with dot notation
 * @function
 * @name CloudflowObject.setParameter
 * @param {Object} pObject
 * @param {String} pField path with dot notation
 * @param {Mixed} pNewValue the new value for that parameter
 * @return {Object} The updated object
 */
CloudflowObject.setParameter = function(pObject, pField, pNewValue) {
    if (typeof pField !== "string" || pField.length <= 0) {
        // if field unknown, make newvalue the new object
        return pNewValue;
    }
    var subKeys = pField.split(".");
    if (!Array.isArray(subKeys)) {
        throw new Error("could not parse key of object " + pField);
    }
    var subObject = pObject;
    for (var i = 0; i < subKeys.length-1; i++) {
        // control if subkey is string, if two points are placed the split retuns a empty string, remove those cases
        if (typeof subKeys[i] === "string" && subKeys[i].length > 0) {
            // prevent errors, make array or object
            if (subObject[subKeys[i]] === undefined) {
                // to control if input is a real number and not a number concat with string parse it to number
                // and do a weak compare with the real string, than we are sur to have a real number
                if(parseInt(subKeys[i+1]) == subKeys[i+1]) {
                    // subkey is number use a array
                    // make it for at least the index can fit
                    subObject[subKeys[i]] = new Array(parseInt(subKeys[i+1]) + 1);
                } else {
                    subObject[subKeys[i]] = {};
                }
            }
            // container exsists or is just made
            subObject = subObject[subKeys[i]];
        } 
        // in other cases do nothing, ingore the error case 
    }
    subObject[subKeys[subKeys.length -1]] = pNewValue;
    return pObject;
};

/**
 * @description Deletes a field from an object given a path
 * @function
 * @name CloudflowObject.deleteParameter
 * @param {Object} pObject
 * @param {String} pField path with dot notation
 * @return {Object} The updated object
 */
CloudflowObject.deleteParameter = function(pObject, pField) {
    if (Array.isArray(pObject) && parseInt(pField) > -1) {
        // skip if parameters is valid array
    } else if (typeof pObject !== "object" || $.isEmptyObject(pObject)) {
        return undefined;
    }
    if (typeof pField !== "string" || pField.length <= 0) {
        return pObject;
    }
    var objectPath = pField.split(".");
    var last = pObject;
    // Loop till one before the last part
    for (var i = 0; i < objectPath.length - 1; i++) {
        // control if subkey is string, if two points are placed the split retuns a empty string, remove those cases
        if (typeof objectPath[i] === "string" && objectPath[i].length > 0) {
            if (last[objectPath[i]] === undefined) {
                // prevent errors, just stop loop
                return undefined;
            } else {
                last = last[objectPath[i]];
            }
        }
        // in other cases do nothing, ingore the error case 
    }
    
    // Delete the last part
    if (Array.isArray(last)) {
        // use splice so length and other indexes are updated
        last.splice(objectPath[objectPath.length - 1], 1);
    } else {
        delete last[objectPath[objectPath.length - 1]];
    }
    return pObject;
};

/**
 * @description Replace value of JSON object
 * @function
 * @name CloudflowObject.travers
 * @param {Object} pObject
 * @param {Function} pReplaceFunction function that is called for each string value, with parameters ("value, "key")
 * @return {Object} The updated object 
 */
CloudflowObject.travers = function(pObject, replaceFunction, pParentKeyPath) {
    if (pParentKeyPath === undefined) {
        pParentKeyPath = "";
    } else  {
        pParentKeyPath += ".";
    }
    $.each(pObject, function(key, child) {
        // if element is a array or object go insite it.
        if ($.isPlainObject(child) || Array.isArray(child)) {
            CloudflowObject.travers(child, replaceFunction, pParentKeyPath + key);
        // if element is a string replace variables    
        } else if (typeof child === "string") {
            pObject[key] = replaceFunction(child, pParentKeyPath + key);
        } 
        // if no array/object or string
        // this can be a number, boolean of function
        // on this moment do nothing
    });
    return pObject;
};

function contains(pParentKeyPath, pObject, pCallBack){
    var found = false;
    if (pParentKeyPath.length > 0) {
        pParentKeyPath += ".";
    }
    $.each(pObject, function(key, child) {
        var ret = pCallBack(pParentKeyPath + key, child);
        if (ret === true) {
            found = true;
            return false;
        } else if (ret === null) {
            return;
        } 
        // if element is a array or object go insite it.
        if ($.isPlainObject(child) || Array.isArray(child)) {
            ret = contains(pParentKeyPath + key, child, pCallBack)
            if (ret === true) {
                found = true;
                return false;
            } else if (ret === null) {
                return;
            } 
        } 
    });
    return found;
}

/**
 * @description Checks if a specific key or value is present in the object
 * @function
 * @name CloudflowObject.contains
 * @param {Object} pObject The object to travers
 * @param {Function} pCallBack The evaluatie itinerate function with parameters key and value, return true if you found it, return null to stop the current run path
 * @example CloudflowObject.contains( {a:"car"}, function(k,v){ return k === "a"; }) 
 * @returns Boolean true if the callback function returns true, false if no return fals has been found      
 */
CloudflowObject.contains = function(pObject, pCallBack) {
    return contains("", pObject, pCallBack);
}

/**
 * @description Get a single difference object from A to B containing all the added and change values and all the deleted keys
 * @function
 * @name CloudflowObject.difference
 * @param {Object} pObjectA flat object of beginning
 * @param {Object} pObjectB flat object of end point            
 * @returns {Object} containing key array deleted with the keys who are removed, an object added with theire values, and an object changed with the new keys
 */
CloudflowObject.difference = function(pObjectA, pObjectB) {
    var differs = {added: {}, deleted: [], changed: {}};
    var keysA = Object.keys(pObjectA);
    var keysB = Object.keys(pObjectB);
    differs.deleted = _.difference(keysA, keysB);
    var addedKeys = _.difference(keysB, keysA);
    for (var i=0; i<addedKeys.length; i++) {
        differs.added[addedKeys[i]] = pObjectB[addedKeys[i]];
    }
    var sameKeys = _.intersection(keysA, keysB);
    for (var i=0; i<sameKeys.length; i++) {
        if (pObjectA[sameKeys[i]] !== pObjectB[sameKeys[i]]) {
            differs.changed[sameKeys[i]] = pObjectB[sameKeys[i]];
        }
    }
    return differs;
}

/**
 * @description Apply the difference object to an object, please use only difference object from {@link CloudflowObject.difference} and in a correct order.
 * Changes wins over adding, delete wins over changes, delete wins over adding, changes only possible if exists.
 * @function
 * @name CloudflowObject.applyDifference
 * @param {Object} pDifference the object containing the differences
 * @param {Object} pObject The old objet where to apply on
 * @returns {Object} the new object containing all the changes
 */
CloudflowObject.applyDifference = function(pDifference, pObject) {
    var newObject = Object.assign({}, pObject);
    if (pDifference && typeof pDifference === "object" && !Array.isArray(pDifference)) {
        if ($.isPlainObject(pDifference.added) && !$.isEmptyObject(pDifference.added)) {
            for (var key in pDifference.added) {
                newObject[key] = pDifference.added[key];
            }
        }
        if ($.isPlainObject(pDifference.changed) && !$.isEmptyObject(pDifference.changed)) {
            for (var key in pDifference.changed) {
                if (key in newObject) {
                    newObject[key] = pDifference.changed[key];
                }
            }
        }
        if (Array.isArray(pDifference.deleted) && pDifference.deleted.length > 0) {
            for (var i=0; i<pDifference.deleted.length; i++) {
                delete newObject[pDifference.deleted[i]];
            }
        }
    }
    return newObject;
}

var flattenRow = function(pKey,pValue, pSkipFn){
    var result = {}
    if (typeof pValue !== "object" || (typeof pSkipFn === "function" && pSkipFn(pValue) === true)){
        result[pKey] = pValue
        return result;
    } else{
        $.each(pValue, function(key, value){
            Object.assign(result,flattenRow(pKey + "." + key, value, pSkipFn));
        });
        return result;
    }
}

/**
 * @description Flatten object with nested object into 1-level object. The keys will use the dot notation. eg. {a: {b: c}} becomes {a.b: c}
 * @function
 * @name CloudflowObject.flatten
 * @param {Object} pObject the object to flatten
 * @param {Object} pSkipFn This function is ran in every step, the first attribute is the current value being processed. If this function returns true that value is kept and no futher flattening is done on that value and possible nested values.
 * @returns {Object} the new flattened object
 */
CloudflowObject.flatten = function(pObject, pSkipFn){
    var result = {}
    if(typeof pSkipFn === "function" && pSkipFn(pObject) === true){
        return pObject;
    }
    $.each(pObject, function(key, value){
        Object.assign(result,flattenRow(key, value, pSkipFn));
    });
    return result;
}

/**
 * @description This function fills the empty indexes in an array between the first and last item with desired value.
 *              Sometimes the value can be "empty" and this function can fill those values with undefined.
 * @function
 * @name CloudflowObject.replaceEmptyArrayRows
 * @param {Object} obj the object to fill
 * @param {Object} replaceWith value to fill the empty positions with
 * @returns {Object} the object with replaced values
 */
CloudflowObject.replaceEmptyArrayRows = function(obj, replaceWith){
    var objCopy;
    if (obj && typeof obj === "object" && !Array.isArray(obj)){
        objCopy = Object.assign({},obj)
        Object.keys(obj).forEach(function(key) {
            if (typeof objCopy[key] === "object" && objCopy[key] !== null) {
                objCopy[key] = CloudflowObject.replaceEmptyArrayRows(objCopy[key],replaceWith)
            }
        })
    } else {
        objCopy = obj.slice(0);
        for (var i = 0; i < obj.length; i++) {
            if(objCopy[i] === undefined || objCopy[i] === null){
                objCopy[i] = replaceWith;
            } else if (typeof objCopy[i] === "object" && objCopy[i] !== null) {
                objCopy[i] = CloudflowObject.replaceEmptyArrayRows(objCopy[i],replaceWith)
            }
        }
    }
    return objCopy;
},

module.exports = CloudflowObject;