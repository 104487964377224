var WhitepaperConnection = require("../../CloudflowUtil/js/WhitepaperConnection.js");
var CloudflowObject = require("../../CloudflowUtil/js/CloudflowObject.js");

/**
 * @constructor WhitepaperCRUD
 * @description A handler to have a CRUD by whitepapers
 * @private
 * @param {pWhitepaper} The name of the whitepaper
 * @param {pWhitepaperNameInput} The input name 
 */
var WhitepaperCRUD = function(pWhitepaper, pWhitepaperNameInput, pType) {
    this._setWhitepaperName(pWhitepaper);
    this._setWhitepaperInputName(pWhitepaperNameInput);
    this.runningConnections = [];
    this.nonAbortableConnections = [];
    this.setType(pType);
};

WhitepaperCRUD.fromJSON = function(json) {
    return new WhitepaperCRUD(json.whitepaper_name, json.input_name, json.type);
};

WhitepaperCRUD.prototype = {
    
    constructor: WhitepaperCRUD,
    
    _setWhitepaperName: function(pWhitepaperName) {
        if (typeof pWhitepaperName !== "string" || pWhitepaperName.length <= 0) {
            throw new Error("_setWhitepaperName: parameter WhitepaperName must be a not empty string");
        }
        this.whitepaper = pWhitepaperName;
    },
    
    _setWhitepaperInputName: function(pWhitepaperInputName) {
        if (typeof pWhitepaperInputName !== "string" || pWhitepaperInputName.length <= 0) {
            throw new Error("_setWhitepaperInputName: parameter WhitepaperinputName must be a not empty string");
        }
        this.whitepaperInputName = pWhitepaperInputName;
    },
    
    create: function(pObject, pGoodCallBack, pBadCallBack) {
        if (this.type === "startFrom") {
            return this._call([pObject], "create", {}, 2, function(pResult){
                if (pResult !== undefined && pResult.document !== undefined && Object.keys(pResult).length === 1) {
                    pGoodCallBack(pResult.document);
                } else {
                    pGoodCallBack(pResult);
                }
            }, pBadCallBack);
        } else {
            var inputparameters = {
                action: "create",
                query: null,
                orderby: null,
                fields: null,
                options: {},
                id: null,
                object: pObject 
            };
            return this._call(inputparameters, undefined, {}, 3, function(pResult){
                if (pResult !== undefined && pResult.document !== undefined && Object.keys(pResult).length === 1) {
                    pGoodCallBack(pResult.document);
                } else {
                    pGoodCallBack(pResult);
                }
            }, pBadCallBack);
        }
    },

    list_with_options: function(pQuery, pOrderby, pFields, pOptions, pGoodCallBack, pBadCallBack) {
        if (this.type === "startFrom") {
            return this._call([pQuery, pOrderby, pFields], "list", pOptions, 1, function(pResults) {
                if (pResults !== undefined && Array.isArray(pResults.documents)) {
                    pGoodCallBack({results: pResults.documents});
                } else {
                    pBadCallBack({error: "database_proxy list", error_code: "database_proxy_list_wrong_returninterface"});
                }
            }, pBadCallBack);
        } else {
            var inputparameters = {
                action: "list_with_options",
                query: pQuery,
                orderby: pOrderby,
                fields: pFields,
                options: pOptions,
                id: null,
                object: null
            };
            return this._call(inputparameters, undefined, {}, 3, pGoodCallBack, pBadCallBack);
        }
    },
    
    get: function(pID, pGoodCallBack, pBadCallBack) {
        if (this.type === "startFrom") {
            return this._call([pID], "get", {}, 3, function(pResult){
                if (pResult !== undefined && pResult.document !== undefined && Object.keys(pResult).length === 1) {
                    pGoodCallBack(pResult.document);
                } else {
                    pGoodCallBack(pResult);
                }
            }, pBadCallBack);
        } else {
            var inputparameters = {
                action: "get",
                query: null,
                orderby: null,
                fields: null,
                options: null,
                id: pID,
                object: null 
            };
            return this._call(inputparameters, undefined, {}, 3, function(pResult){
                if (pResult !== undefined && pResult.document !== undefined && Object.keys(pResult).length === 1) {
                    pGoodCallBack(pResult.document);
                } else {
                    pGoodCallBack(pResult);
                }
            }, pBadCallBack);
        }
    },
    
    count: function(pQuery, pGoodCallBack, pBadCallBack) {
        if (this.type === "startFrom") {
            return this._call([pQuery], "count", {}, 1, pGoodCallBack, pBadCallBack);
        } else {
            var inputparameters = {
                action: "count",
                query: pQuery,
                orderby: null,
                fields: null,
                options: null,
                id: null,
                object: null 
            };
            return this._call(inputparameters, undefined, {}, 3, pGoodCallBack, pBadCallBack);
        }
    },
    
    update: function(pObject, pGoodCallBack, pBadCallBack) {
        if (this.type === "startFrom") {
            return this._call([pObject], "update", {}, 2, function(pResult){
                if (pResult !== undefined && pResult.document !== undefined && Object.keys(pResult).length === 1) {
                    pGoodCallBack(pResult.document);
                } else {
                    pGoodCallBack(pResult);
                }
            }, pBadCallBack);
        } else {
            var inputparameters = {
                action: "update",
                query: null,
                orderby: null,
                fields: null,
                options: {},
                id: null,
                object: pObject 
            };
            return this._call(inputparameters, undefined, {}, 3, function(pResult){
                if (pResult !== undefined && pResult.document !== undefined && Object.keys(pResult).length === 1) {
                    pGoodCallBack(pResult.document);
                } else {
                    pGoodCallBack(pResult);
                }
            }, pBadCallBack);
        }
    },

    set_keys: function(pID, pKeyData, pOptions, pGoodCallBack, pBadCallBack) {
        if (this.type === "startFrom") {
            //throw new Error("unsupported set_keys for DBIO");
            //return this._call([pID, pKeyData], "set_keys", {}, 2, pGoodCallBack, pBadCallBack);
            var that = this;
            var outputDef = $.Deferred();
            this.get(pID, function(pResult) {
                for (var key in pKeyData) {
                    pResult = CloudflowObject.setParameter(pResult, key, pKeyData[key]);
                }
                that.update(pResult, function(pReturnResult){
                    pGoodCallBack(pReturnResult);
                    outputDef.resolve(pReturnResult);
                }, function(pError){
                    pBadCallBack(pError);
                    outputDef.reject(pError);
                });
            }, function(pError) {
                pBadCallBack(pError);
                outputDef.reject(pError);
            });
            return outputDef;
        } else {
            var inputparameters = {
                action: "set_keys",
                query: null,
                orderby: null,
                fields: null,
                options: null,
                id: pID,
                object: pKeyData 
            };
            if (pOptions.oldKeyData !== undefined) {
                inputparameters.old_key_data = pOptions.oldKeyData;
            }
            return this._call(inputparameters, undefined, {}, 3, pGoodCallBack, pBadCallBack);
        }
    },

    set_keys_by_query: function(pQuery, pKeyData, pOptions, pGoodCallBack, pBadCallBack) {
        if (this.type === "startFrom") {
            //throw new Error("unsupported set_keys_by_query for DBIO");
            //return this._call([pQuery, pKeyData, pOptions], "set_keys_by_query", {}, 2, pGoodCallBack, pBadCallBack);
            var that = this;
            var outputDef = $.Deferred();
            this.list_with_options(pQuery, [], [], pOptions, function(pResult) {
                var dataDefs = [];
                if (pResult !== undefined && Array.isArray(pResult.results)) {
                    for (var i=0; i<pResult.results.length; i++) {
                        for (var key in pKeyData) {
                            pResult.results[i] = CloudflowObject.setParameter(pResult.results[i], key, pKeyData[key]);
                        }
                        (function(index){
                            dataDefs.push($.Deferred(function(pDefer){
                                that.update(pResult.results[index], pDefer.resolve, pDefer.resolve);
                            }));
                        })(i);
                    }
                }
                $.when.apply($, dataDefs)
                .fail(function(pError){
                    pGoodCallBack({});
                    outputDef.resolve({});
                })
                .done(function(pResult){
                    pGoodCallBack({});
                    outputDef.resolve({});
                })
            }, function(pError) {
                pBadCallBack(pError);
                outputDef.reject(pError);
            });
            return outputDef;
        } else {
            var inputparameters = {
                action: "set_keys_by_query",
                query: pQuery,
                orderby: null,
                fields: null,
                options: pOptions,
                id: null,
                object: pKeyData 
            };
            return this._call(inputparameters, undefined, {}, 3, pGoodCallBack, pBadCallBack);
        }
    },

    delete: function(pID, pGoodCallBack, pBadCallBack) {
        if (this.type === "startFrom") {
            return this._call([pID], "delete", {}, 2, pGoodCallBack, pBadCallBack);
        } else {
            var inputparameters = {
                action: "delete",
                query: null,
                orderby: null,
                fields: null,
                options: null,
                id: pID,
                object: null
            };
            return this._call(inputparameters, undefined, {}, 3, pGoodCallBack, pBadCallBack);
        }
    },


    delete_by_query: function(pQuery, pGoodCallBack, pBadCallBack){
        if (this.type === "startFrom") {
            if (pQuery.length > 0) {
                /** @todo not tested !! */
                if (pQuery.length === 3 && pQuery[0] === "_id" && pQuery[1] === "equal to") {
                    return this._call([pQuery[2]], "delete", {}, 2, pGoodCallBack, pBadCallBack);
                } else if (pQuery.length === 3 && pQuery[0] === "_id" && pQuery[1] === "in" && Array.isArray(pQuery[2])) {
                    var defIDs = [], that = this;
                    for (var i=0; i<pQuery[2].length; i++) {
                        (function(index){
                            defIDs.push($.Deferred(function(pDef){
                                that._call([pQuery[2][index]], "delete", {}, 2, pDef.resolve, pDef.reject);
                            }))
                        }(i));
                    }
                    return $.when.apply($, defIDs).then(pGoodCallBack, pBadCallBack);
                } else {
                    pBadCallBack("wrong format");
                }
            } else {
                pBadCallBack("wrong format");
            }
        } else {
            var inputparameters = {
                action: "delete_by_query",
                query: pQuery,
                orderby: null,
                fields: null,
                options: {},
                id: null,
                object: null
            };
            return this._call(inputparameters, undefined, {}, 3, pGoodCallBack, pBadCallBack);
        }
    },

    _call: function(pParameters, pFunctionName, pOptions, pStartPollingTime, pGoodCallBack, pBadCallBack) {
        var longLive = ["delete", "delete_by_query", "update", "create", "set_keys", "set_keys_by_query"].indexOf(pFunctionName) >= 0;
        if (this.type === "startFrom") {
            var that = this;
            var type =  {
                connectionType : "database_proxy",
                connectionFunctionName: pFunctionName,
                connectionCallOptions: pOptions
            };
            var connection = new WhitepaperConnection(this.whitepaper, this.whitepaperInputName, pParameters, type, longLive);
            connection.setStartPollingTime(pStartPollingTime);
            this._addRunningConnection(connection, longLive === false);
            return connection.start()
                             .done(pGoodCallBack)
                             .fail(pBadCallBack)
                             .always(function(){
                                that._removeRunningConnection(connection);
                             });
        } else {
            var connection = new WhitepaperConnection(this.whitepaper, this.whitepaperInputName, pParameters, undefined, longLive);
            return connection
                .httpService()
                .done(pGoodCallBack)
                .fail(pBadCallBack);
        }
    },
  
    setType: function(pType) {
        if (pType === "httpService") {
            this.type = "httpService";
        } else {
            // default value
            this.type = "startFrom";
        }
    },
    
    getType: function() {
        return this.type;
    },
    
    _addRunningConnection: function(pConnection, pAbortable) {
        if (pAbortable === true) {
            this.runningConnections.push(pConnection);
        } else {
            // may not abort
            this.nonAbortableConnections.push(pConnection);
        }
    },
    
    _removeRunningConnection: function(pConnection) {
        for (var i=0; i<this.runningConnections.length; i++) {
            if (this.runningConnections[i] === pConnection) {
                this.runningConnections.splice(i, 1);
                return i;
            }
        }
        for (var i=0; i<this.nonAbortableConnections.length; i++) {
            if (this.nonAbortableConnections[i] === pConnection) {
                this.nonAbortableConnections.splice(i, 1);
                return i;
            }
        }
        return -1;
    },
    
    stop: function(pReason) {
        for (var i=0; i<this.runningConnections.length; i++) {
            this.runningConnections[i].stop(pReason);
        }
        this.runningConnections = [];
    },
    
    countRunningConnections: function(){
        return this.runningConnections.length + this.nonAbortableConnections.length;
    },
    
    toJSON: function() {
        return {
            whitepaper_name: this.whitepaper,
            input_name: this.whitepaperInputName,
            type: this.type
        };
    },
    
    clone: function(){
        return WhitepaperCRUD.fromJSON(this.toJSON());
    }
    
};

module.exports = WhitepaperCRUD;